//SelectPosts.js
import React, { useState, useEffect, useCallback } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import { db, auth } from '../Firebase/FirebaseConfig'
import {
  getDocs,
  collection,
  doc,
  orderBy,
  query
} from 'firebase/firestore'
import '../Styling/Project.css'
import '../Styling/Default.css'
import generateReport from '../Components/GenerateReport'

import {
  Button,
  Container,
  CircularProgress,
  Backdrop,
  Grid,
  Checkbox,
  Paper,
  IconButton,
  Snackbar,
  Box,
  Typography,
  useMediaQuery,
  Badge
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ArrowDownward, ArrowUpward, ArrowBack } from '@mui/icons-material'

const UserPosts = ( {
  handleDataChange,
  handlePostsSubmit,
  selectedPresentation,
  selectedTemplate,
  newPresLoading
}) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [loadingType, setLoadingType] = useState("")
  const [user, loading, error] = useAuthState(auth)
  const [allProjects, setAllProjects] = useState([])
  const [selectedData, setSelectedData] = useState({})
  const [shortSelectedData, setShortSelectedData] = useState({}) 
  const [selectedProject, setSelectedProject] = useState(null) //current project being viewed
  const [projectAscend, setProjectAscend] = useState(true) //toggle ascending vs descending dates
  const [checkProject, setCheckProject] = useState({})

  let tempPosts
  let emptyProject

  const mobile = useMediaQuery('(max-width:600px)')

  //loads user projects from firebase
  const getUserProjects = async ( ) => {
    try {
      const projects = []
      const userRef = doc(db, 'users', user.uid)
      const projectsRef = collection(userRef, 'projects')
      const q = query(projectsRef, orderBy('created-date', 'desc'))
      const projSnapshot = await getDocs(q)

      projSnapshot.forEach((doc) => {
        const projDate = doc.data()['created-date'].toDate().toLocaleDateString()

        if (doc.data().posts != null) {
          tempPosts = doc.data().posts
          emptyProject = false
        } else {
          tempPosts = 'No Posts'
          emptyProject = true
        }
        const project = {
          id: doc.id,
          title: doc.data().title,
          date: projDate,
          posts: tempPosts,
          empty: emptyProject
        }
        projects.push(project)
      })
      setAllProjects(projects)
      let tmp = []
      projects.forEach((project, index) => {
        tmp[index] = []
      })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (loading) return
    getUserProjects()
  }, [user])

  
  useEffect(() => {
    //if data is selected, set bool
    const lengthTemp = Object.keys(selectedData).length;
    if(lengthTemp == 0){
      handleDataChange(false)
    }else{
      handleDataChange(true)
    }
  }, [selectedData])

  const handleAscend = () => {
    setProjectAscend(!projectAscend)
  }

  const handleProjectBack = () => {
    setSelectedProject(null)
  }

  useEffect(() => {
    let tmp = [...allProjects]
    if (projectAscend) {
      tmp = tmp.sort((a, b) => b.date - a.date)
    } else {
      tmp = tmp.sort((a, b) => a.date - b.date)
    }
    setAllProjects(tmp)
  }, [projectAscend])

  const viewReport = (report) => {
    let descriptions = Object.values(selectedData)
      .filter((pair) => pair.caption)
      .map((pair) => pair.caption)
    navigate('/ViewReport', {
      state: {
        report: report,
        edit: true,
        selectedData: descriptions,
        addSlide: true,
        selectedPresentation: selectedPresentation,
        selectedTemplate: selectedTemplate,
      }
    })
  }

  
  
  const handleSelect = (id, index, value, type, checked, date, title) => { //selects a post
    if (checked) {
      setSelectedData((prev) => {
        let tmp = { ...prev }
        tmp[`${id}_${index}`] = { ...tmp[`${id}_${index}`], [type]: value }
        if( tmp[`${id}_${index}`]['date'] == undefined){
          tmp[`${id}_${index}`] = { ...tmp[`${id}_${index}`], ['date']: date }
        }
        if( tmp[`${id}_${index}`]['title'] == undefined){
          tmp[`${id}_${index}`] = { ...tmp[`${id}_${index}`], ['project']: title }
        }
        return tmp
      })

      setShortSelectedData((prev) => {
        let tmp2 = { ...prev }
        tmp2[`${id}_${index}`] = { ...tmp2[`${id}_${index}`], [type]: value }
        return tmp2
      })

    } else {
      setSelectedData((prev) => {
        let tmp = { ...prev }
        delete tmp[`${id}_${index}`][type]
        if (Object.keys(tmp[`${id}_${index}`]).length == 2) {
          delete tmp[`${id}_${index}`]
        }
        return tmp
      })

      setShortSelectedData((prev) => {
        let tmp2 = { ...prev }
        delete tmp2[`${id}_${index}`][type]
        if (Object.keys(tmp2[`${id}_${index}`]).length == 0) {
          delete tmp2[`${id}_${index}`]
        }
        return tmp2
      })
    }
  }

  const selectAll = (checked) => { //selects all posts in current project
    let project = allProjects[selectedProject]
    project.posts.map((post, index) => {
      if (checked) {
        handleSelect(project.id, index, post.description, 'caption', checked, project.date, project.title)
        handleSelect(project.id, index, post['image-url'], 'image-url', checked, project.date, project.title)
      } else {
        setSelectedData((prev) => {
          let tmp = { ...prev }
          delete tmp[`${project.id}_${index}`]
          return tmp
        })
        setShortSelectedData((prev) => {
          let tmp2 = { ...prev }
          delete tmp2[`${project.id}_${index}`]
          return tmp2
        })
      }
    })
  }

  const isChecked = (type, value) => { //checks if a value is selected
    return Object.values(selectedData)
      .map((data) => data[type])
      .includes(value)
  }

  const allChecked = () => { //checks if ALL posts in a project are selected
    let project = allProjects[selectedProject]
    let projectPosts = project.posts
    let test = (Object.prototype.toString.call(projectPosts) === '[object Array]')
    if (test === true){
    return project.posts.every((post, index) => {
      let indexId = `${project.id}_${index}`
      if (selectedData[indexId] == undefined) {
        return false
      } else {
        if (selectedData[indexId].caption !== post.description) {
          return false
        }
        if (selectedData[indexId]['image-url'] !== post['image-url']) {
          return false
        }
        return true
      }
    })
    } else {
      return false
    }
  }

  const handleReset = () => {
    setSelectedData({})
    setShortSelectedData({})
  }

  const RenderPosts = () => {
    let showCheckbox
    let item = allProjects[selectedProject]
    // let index = selectedProject
    return (
      <>
        {item.empty == true ? (
          <div>
            <b>No Posts</b>
          </div>
        ) : (
          <>
            {item.posts.map((post, index) => {
              if (post.description === undefined) {
                showCheckbox = false
              } else {
                showCheckbox = true
              }
              let date = new Date(post['created-date'].toDate()).toDateString()
              let localeDate = new Date(post['created-date'].toDate()).toLocaleDateString()
              return (
                <Paper sx={{ marginBottom: '20px', width: '100%' }} key={index}>
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '15px 15px',
                      borderBottom: '1px solid grey',
                      justifyContent: 'right'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        padding: '10px 10px',
                        borderRadius: '25px',
                        backgroundColor: (selectedData[`${item.id}_${index}`]) ? '#7bafd4' : null
                      }}
                    >
                    <Typography sx={{ flexGrow: 1, textAlign: 'right' }}>
                      {date}
                    </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '15px 15px',
                      backgroundColor: isChecked('image-url', post['image-url'])
                        ? '#7bafd4'
                        : null
                    }}
                  >
                    <Checkbox
                      // type='checkbox'
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                      id={post['image-url']}
                      value={post['image-url']}
                      onChange={(e) =>
                        handleSelect(
                          item.id,
                          index,
                          e.target.value,
                          'image-url',
                          e.target.checked,
                          localeDate,
                          item.title
                        )
                      }
                      checked={isChecked('image-url', post['image-url'])}
                    />
                    <span>
                      <img className='image_box' src={post['image-url']} />
                    </span>
                  </Box>
                  {showCheckbox == true ? (
                    <Box
                      sx={{
                        padding: '15px 15px',
                        display: 'flex',
                        textAlign: 'left',
                        backgroundColor: isChecked('caption', post.description)
                          ? '#7bafd4'
                          : null
                      }}
                    >
                      <Checkbox
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                        // type='checkbox'
                        value={post.description}
                        onChange={(e) =>
                          handleSelect(
                            item.id,
                            index,
                            e.target.value,
                            'caption',
                            e.target.checked,
                            localeDate,
                            item.title
                          )
                        }
                        checked={isChecked('caption', post.description)}
                      />
                      <span>{post.description}</span>
                    </Box>
                  ) : (
                    <>
                      {' '}
                      <div>No Description</div>
                    </>
                  )}
                </Paper>
              )
            })}
          </>
        )}
      </>
    )
  }

  let selectedDataLength = [].concat(
    ...Object.values(shortSelectedData).map((pair) => Object.values(pair))
  ).length

  const FloatingActions = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <IconButton onClick={() => handleReset()}>
          <CloseIcon sx={{ color: '#fff' }} />
        </IconButton>
        <Typography sx={{ flexGrow: 1 }}>
          {selectedDataLength <= 1
            ? '1 Item Selected'
            : `${selectedDataLength} Items Selected`}
        </Typography>
        {newPresLoading ? (
          <Typography style={{ margin: 'auto 10px' }}>Creating Presentation . . .</Typography>
        ) : (
        <>  
        <Button
          variant='contained'
          disabled={newPresLoading}
          onClick={() => {
            setIsLoading(true)
            setLoadingType("Report")
            generateReport(selectedData).then((resp) => {
              viewReport(resp)
            })
          }}
        >
          Generate Report
        </Button>

        <Button
          variant='contained'
          disabled={newPresLoading}
          onClick={() => {
            setIsLoading(true)
            setLoadingType("Presentation")
            handlePostsSubmit(selectedData)
          }}
        >
          Add to Presentation 
        </Button>
        </>
        )}
        
      </Box>
    )
  }
  
  const showBadge = (id) => {
    //checks if project id exists in selected data
    return (
      Object.keys(selectedData).filter((string) => string.indexOf(id) > -1)
        .length == 0
    )
  }

  const SelectProjects = () => {
    let styling;
    if(mobile === true){
      styling = { display: 'flex', flexDirection: 'column', gridRowStart: '2' }
    }else{
      styling = {     
        display: 'flex',     
        flexDirection: 'column',     
        gridRowStart: '2',     
        height: '600px',     
        overflow: 'hidden',     
        overflowY:'scroll'
      }
    }

    return (
      <>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography
            sx={{ flexGrow: 1, textAlign: 'left', fontWeight: 'bold' }}
          >
            Project Name
          </Typography>
          <Button
            onClick={() => handleAscend()}
            sx={{ textTransform: 'none' }}
            endIcon={projectAscend ? <ArrowUpward /> : <ArrowDownward />}
          >
            Date Created
          </Button>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}></Box>
        <Box
          sx={styling}
        >
          {allProjects.map((project, index) => {
            let isSelected = false
            if (selectedProject == index) {
              isSelected = true
            }
            let dateString = new Date(project.date).toDateString()
            return (
              <Badge
                // invisible={checkProject[index].length == 0}
                invisible={showBadge(project.id)}
                color='primary'
                variant='dot'
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
              >
                <Paper
                  sx={{
                    display: 'flex',
                    width: '100%',
                    marginBottom: '10px',
                    backgroundColor: isSelected ? '#7bafd4' : null,
                    color: isSelected ? '#fff' : null,
                    padding: '10px 10px'
                  }}
                  key={`${project.title}-${index}`}
                  onClick={() => setSelectedProject(index)}
                >
                  <Typography
                    sx={{
                      flexGrow: 1,
                      textAlign: 'left',
                      fontWeight: 'bold'
                    }}
                  >
                    {project.title}
                  </Typography>
                  <Typography>{dateString}</Typography>
                </Paper>
              </Badge>
            )
          })}
        </Box>
      </>
    )
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={selectedDataLength > 0}
        message={isLoading ? 'Generating '+ loadingType + ' . . .': <FloatingActions />}
      />

      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={isLoading}
      >
        <CircularProgress color='secondary' />
      </Backdrop>

      <div style={{ marginTop: '10px' }}>
        {mobile ? (
          <>
            {typeof selectedProject !== 'number' ? (
              <Box sx={{ width: '100%', borderBottom: '1px grey solid', paddingTop: '30px' }}>
                <Typography variant='h2' fontSize='40px'>Select Posts</Typography>
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    width: '100%',
                    borderBottom: '1px grey solid',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <IconButton onClick={() => handleProjectBack()}>
                    <ArrowBack />
                  </IconButton>
                  <Typography fontWeight={'bold'}>{allProjects[selectedProject].title}</Typography>
                </Box>
              </>
            )}
            <Container sx={{ marginBottom: '100px' }}>
              {typeof selectedProject !== 'number' ? (
                <>
                  <Box>
                    <SelectProjects />
                  </Box>
                </>
              ) : (
                <>
                  <Box>
                    {allProjects.length > 0 ? (
                      typeof selectedProject == 'number' ? (
                        <>
                        <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          paddingLeft: '20px',
                          alignItems: 'center'
                        }}
                      >
                        <Checkbox
                          id={`${selectedProject}_all`}
                          checked={allChecked()}
                          onChange={(e) => selectAll(e.target.checked)}
                        />
                        <Typography>Select all posts</Typography>
                      </Box>
                        <RenderPosts />
                        </>
                      ) : (
                        'Select a Project'
                      )
                    ) : (
                      <div>loading</div>
                    )}
                  </Box>
                </>
              )}
            </Container>
          </>
        ) : (
          <>
            {/* <Box sx={{ width: '100%', borderBottom: '1px grey solid' }}>
              <Typography variant='h2'>Select Posts</Typography>
            </Box> */}
            <Container>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '40% auto'
                }}
              >
                {/* <Box> */}
                <SelectProjects />
                {/* </Box> */}

                <Box>
                  {allProjects.length > 0 ? (
                    typeof selectedProject == 'number' ? (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            paddingLeft: '20px',
                            alignItems: 'center'
                          }}
                        >
                          <Checkbox
                            id={`${selectedProject}_all`}
                            checked={allChecked()}
                            onChange={(e) => selectAll(e.target.checked)}
                          />
                          <Typography>Select all posts</Typography>
                        </Box>
                        <RenderPosts />
                      </>
                    ) : (
                      'Select a Project'
                    )
                  ) : (
                    <div>loading</div>
                  )}
                </Box>
              </Box>
            </Container>
          </>
        )}
      </div>
    </>
  )
}
export default UserPosts