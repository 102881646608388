//User profile page
import React, { useState, useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import { Avatar, Grid, Tabs, Tab, useMediaQuery, Box } from '@mui/material'
import '../Styling/Login.css'
import '../Styling/Profile.css'
import '../Styling/Default.css'
import { db, auth } from '../Firebase/FirebaseConfig'
import {
  getDoc,
  collection,
  doc,
  query,
  where,
  orderBy,
  getDocs
} from 'firebase/firestore'
import { onAuthStateChanged } from 'firebase/auth'
import ProjectBox from '../Components/ProjectBox'
import Report from '../Components/Report'
import Friends from '../Components/Friends'
import ContentContainer from '../Components/ContentContainer'
import { useLocation } from 'react-router-dom'

const DisplayProfile = () => {
  const navigate = useNavigate()
  const [user, loading, error] = useAuthState(auth)
  const [uid, setUid] = useState(null)
  const [profile, setProfile] = useState([])
  const [pictureUrl, setPictureUrl] = useState(null)
  const { prevTab } = useLocation().state
  const [currTab, setCurrTab] = useState(prevTab)
  const [allReports, setAllReports] = useState([])
  const mobile = useMediaQuery('(max-width:600px)')
  const [role, setRole] = useState()
  const [selectedProject, setSelectedProject] = useState(new Map())
  const userRef = doc(db, 'users', user.uid)
  const projectsRef = collection(userRef, 'projects')

  const AuthStateChange = () => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUid(user.uid)
      } else {
        // console.log('NO USER SIGNED IN')
      }
    })
  }

  const GetReports = async () => {
    let tempTitle = ''
    const reportsRef = collection(db, 'reports')
    const q = query(
      reportsRef,
      where('user-id', '==', user.uid),
      orderBy('date-created', 'desc')
    )
    const reports = []
    let reportDate
    try {
      const reportSnapshot = await getDocs(q)
      //console.log('created snapshot')
      reportSnapshot.forEach((doc) => {
        if (doc.data()['date-created']) {
          reportDate = doc.data()['date-created'].toDate().toLocaleDateString()
        } else {
          reportDate = 'No Date'
        }
        if (doc.data().title == null) {
          tempTitle = 'No Title'
        } else {
          tempTitle = doc.data().title
        }
        let report
        if (typeof doc.data()['currentVer'] !== 'undefined') {
          let currentData = doc.data().versions[doc.data().currentVer]
          report = {
            id: doc.id,
            title: tempTitle,
            date: reportDate,
            essay: currentData.essay,
            figures: currentData.figures,
            descriptions: doc.data().descriptions
          }
        } else {
          report = {
            id: doc.id,
            title: tempTitle,
            date: reportDate,
            essay: doc.data().essay,
            figures: doc.data().figures,
            descriptions: doc.data().descriptions
          }
        }
        reports.push(report)
      })
      setAllReports(reports)
    } catch (err) {
      console.log('error:', err)
    }
  }

  const getProfile = async () => {
    try {
      const userSnapshot = await getDoc(userRef)
      const currUser = userSnapshot.data()
      setProfile(currUser)
      let userRole = 2;

      if (userSnapshot.data()['profile-image-url']) {
        const image = userSnapshot.data()['profile-image-url']
        setPictureUrl(image)
      }

      if (userSnapshot.data().hasOwnProperty('role')) {
        userRole =  userSnapshot.data()['role'] 
      }
      setRole(userRole)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (loading) return
    GetReports()
    if (!user) {
      return navigate('/')
    } else {
      AuthStateChange()
      getProfile()
    }
  }, [user, loading, navigate])

  const handleTabChange = (event, newValue) => {
    setCurrTab(newValue)
  }

  const handleEditToggle = (report) => {
    const { descriptions, ...rest } = report
    navigate('/ViewReport', {
      state: {
        report: rest,
        edit: true,
        selectedData: descriptions
      }
    })
  }
  const handleViewReport = (edit, report, pictureUrl, user) => {
    const { descriptions, ...rest } = report
    navigate('/ViewReport', {
      state: {
        report: rest,
        edit: edit,
        selectedData: descriptions,
        pictureUrl: pictureUrl,
        user: user
      }
    })
  }

  const handleEditProject = (proj) => {
    setSelectedProject(proj)
  }

  const navigateNow = () => {
    navigate('/EditProject', { state: { 
      id: selectedProject.id,
      title: selectedProject.title,
      posts: selectedProject.posts,
      date: selectedProject.date
    } })
  }

  useEffect(() => {
    if (selectedProject.size !== 0){
      navigateNow()
    }
  },[selectedProject])

  let styles = {
    mobileTabs: {
      minHeight: '0px',
      marginTop: '5px',
      '.MuiTab-root': { minHeight: '0px', minWidth: '0px', padding: '8px 10px' }
    }
  }

  return (
    <>
      <div class='wrapper'>
        <div
          style={{
            backgroundColor: '#ffffffff',
            padding: '40px 40px 0px 40px'
          }}
        >
          <Grid container spacing={2}>
            <Grid xs={12} sx={{ display: 'flex' }}>
              <Avatar
                src={pictureUrl}
                sx={{
                  width: '90px',
                  margin: '0px 20px',
                  height: '90px'
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}
              >
                <h1 style={{ textAlign: 'left', margin: '0px 0px 5px 0px' }}>
                  {profile.name}{' '}
                </h1>
                <p style={{ textAlign: 'left', margin: '0px 0px 5px 0px' }}>
                  {' '}
                  {profile.bio}{' '}
                </p>
                <p style={{ textAlign: 'left', margin: '0px' }}>
                  <i>"{profile.quote}"</i>
                </p>
              </div>
            </Grid>
            <Grid sx={{ display: 'flex', justifyContent: 'center' }} xs={12}>
              <Tabs
                value={currTab}
                onChange={handleTabChange}
                sx={mobile ? styles.mobileTabs : null}
              >
                <Tab label='Posts' />
                {role >= 2 ? (
                <Tab label='Reports' />
                ) : (<></>) }
                {role >= 1 ? (
                <Tab label='Friends' />
                ):(<></>)}
                {/* TODO: Build Presentations Tab View */}
                {/* <Tab label='Presentations' /> */}
              </Tabs>
            </Grid>
          </Grid>
        </div>

        {currTab == 0 ? (
          <>
            <ProjectBox 
              projectsRef={projectsRef} 
              handleEditProject={handleEditProject}
              parentComponent='profile' />
          </>
        ) : (
          <></>
        )}
        {currTab == 1 ? (
          <>
            <ContentContainer>
              {allReports.map((report) => {
                return (
                  <Report
                    report={report}
                    sx={{ marginTop: '20px' }}
                    pictureUrl={pictureUrl}
                    user={profile.name}
                    handleEditToggle={() =>
                      handleViewReport(true, report, pictureUrl, profile.name)
                    }
                    handleViewReport={() =>
                      handleViewReport(false, report, pictureUrl, profile.name)
                    }
                  />
                )
              })}
            </ContentContainer>
          </>
        ) : (
          <></>
        )}

        {currTab == 2 ? (
          <>
            <Friends userRef={userRef} userProfile={profile} />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default DisplayProfile
