//temporary solution to view a single report for saving as pdf or for screenshots
import React, { useState, useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import '../Styling/Project.css'
import '../Styling/Default.css'
import {
  Card,
  CardHeader,
  CardContent,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Avatar,
  TextField,
  Paper,
  Divider,
  Typography,
  Backdrop,
  CircularProgress,
  Box,
  List,
  ListItemButton,
  IconButton,
  Drawer,
  useMediaQuery
} from '@mui/material'
import Report from '../Components/Report'
import { ArrowBack } from '@mui/icons-material'

import { db, auth } from '../Firebase/FirebaseConfig'
import { Grid, Button } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import generateReport from '../Components/GenerateReport'
import UpdateReport from '../Components/UpdateReport'
import {
  addMultipleSlides,
} from '../GoogleAPIs/ScriptAPI';

const ViewSingleReport = () => {
  const { edit, report, selectedData, addSlide, selectedPresentation, selectedTemplate, pictureUrl, user } = useLocation().state
  const navigate = useNavigate()
  const [isEdit, setIsEdit] = useState(edit)
  const [showAddSlide, setShowAddSlide] = useState(addSlide)

  let initVersions //check if report was created with version history
  if (typeof report['currentVer'] !== 'undefined') {
    initVersions = [...report.versions]
  } else {
    initVersions = [{ ...report }]
  }
  const [verHistory, setVerHistory] = useState(initVersions)
  const [currView, setCurrView] = useState(0)
  const [title, setTitle] = useState(report.title)
  const [isLoading, setIsLoading] = useState(false)
  const mobile = useMediaQuery('(max-width:600px)')
  const mobileVersionControl = useMediaQuery('(max-width:900px)')

  const handleChange = (type, value, index) => {
    if (type == 'caption') {
      setVerHistory((prev) => {
        let tmp = [...prev]
        tmp[currView].figures[index]['caption'] = value
        return tmp
      })
    } else {
      setVerHistory((prev) => {
        let tmp = [...prev]
        tmp[currView][type] = value
        return tmp
      })
    }
  }
  const handleTitleChange = (value) => {
    setTitle(value)
  }

  const updateReport = async () => {
    return new Promise((resolve) => {
      let update = UpdateReport(currView, verHistory, report.id, title)
      resolve(update)
    })
  }

  const handleSave = () => {
    updateReport().then((resp) => {
      setIsEdit(false)
    })
  }

  const handleEditToggle = () => {
    setIsEdit(!isEdit)
  }

  const regenerateReport = async () => {
    let completeData = {
      essayData: selectedData,
      captionPairData: verHistory[currView].figures
    }
    return new Promise((resolve) => {
      let regen = generateReport(completeData, report.id)
      resolve(regen)
    })
  }

  const handleRegen = () => {
    setIsLoading(true)
    regenerateReport().then((resp) => {
      setVerHistory((prev) => {
        let tmp = [...prev]
        tmp.push(resp)
        return tmp
      })
      setCurrView((prev) => prev + 1)
      setIsLoading(false)
    })
  }

  const [showVersions, setShowVersions] = useState(null)
  const openVersions = Boolean(showVersions)

  const toggleShowVersions = () => {
    setShowVersions(!showVersions)
  }

  const handleSubmitToSlides = async() => {
    setIsLoading(true) 
    const presID = selectedPresentation.presId;
    const newReport = [];
    let obj;

    obj = {
      key: report.id,
      value: {
        id: report.id,
        date: report['date-created'],
        essay: verHistory[0].essay,
        figures: verHistory[0].figures,
        title: report.title,
      }
    }
    newReport.push(obj);
    const reportMap = new Map(newReport.map((obj) => [obj.key, obj.value]))

    await addMultipleSlides(reportMap, presID, selectedTemplate).then(
      (response) => {
        setIsLoading(false)
        navigateNextStep()
      }
    )
  }

  const navigateNextStep = () => {
    navigate('/ViewAllReports', { 
      state: { 
        prevStep: 3,
        fromViewReport: true,
        prevPresentation: selectedPresentation, 
        prevTemplate: selectedTemplate 
      } 
    })
  }

  const versionControl = () => {
    return (
      <>
        <Typography
          fontWeight='bold'
          align='left'
          sx={{ padding: '10px 10px 0px 10px' }}
        >
          Version Control
        </Typography>
        <List
          sx={{
            // selected and (selected + hover) states
            '&& .Mui-selected, && .Mui-selected:hover': {
              backgroundColor: '#1976d2',
              '&, & .MuiListItemIcon-root': {
                color: '#fff'
              }
            }
          }}
        >
          {verHistory.map((item, index) => {
            return (
              <>
                <ListItemButton
                  selected={currView == index}
                  onClick={() => setCurrView(index)}
                >
                  Version {index}
                </ListItemButton>
              </>
            )
          })}
        </List>
        <Divider />
        <Button onClick={() => handleRegen()}>Regenerate</Button>
      </>
    )
  }
  return (
    <>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={isLoading}
      >
        <CircularProgress color='secondary' />
      </Backdrop>
      <Box
        sx={{
          display: 'grid',
          width: '100%',
          gridTemplateColumns: { xs: '10% auto 10%', sm: '15% 70% 15%', lg: '25% 50% 13% 12%' }, //keep these values the same as ContentContainer.js
          marginTop: mobile ? '0px' : '20px',
          marginBottom: '100px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gridRow: '1/2',
            gridColumn: '1/2',
          }}
        >
          <IconButton
            onClick={() => navigate('/UserProfile', { state: { prevTab: 1 } })}
            sx={{ margin: mobile ? '20px 0 0 0' : '0 0 0 20px' }}
          >
            <ArrowBack />
          </IconButton>
        </Box>
        <Box
          sx={{ gridColumn:  {xs: '1 /4', sm: '2/3'}, gridRow: '1/2'}}
        >
          <Report
            report={verHistory[currView]}
            pictureUrl={pictureUrl}
            user={user}
            date={report['date-created']}
            isEdit={isEdit}
            handleEditToggle={handleEditToggle}
            handleSave={handleSave}
            handleChange={handleChange}
            handleTitleChange={handleTitleChange}
            title={title}
            singleView={true}
            handleShowVersions={toggleShowVersions}
            handleSubmitToSlides={handleSubmitToSlides}
            addSlide={showAddSlide}
          />
          <div
            id='report-edit-actions'
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          ></div>
        </Box>
        <Box sx={{ gridColumn: { xs: '2/3', md: '3/4' } }}>
          {isEdit ? (
            <>
              {mobileVersionControl ? (
                <Drawer
                  anchor={'bottom'}
                  open={openVersions}
                  onClose={toggleShowVersions}
                >
                  {versionControl()}
                </Drawer>
              ) : (
                <Paper>{versionControl()}</Paper>
              )}
            </>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </>
  )
}
export default ViewSingleReport
