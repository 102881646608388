import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from './Firebase/FirebaseConfig'
import './App.css'
import LogInUser from './Components/Login'
import DisplayProfile from './Pages/Profile'
// import LoogedOut from './Components/LoggedOut';
// import ViewResponse from './Components/ViewEssay';
import UserPosts from './Pages/SelectPosts'
import UserAIReports from './Pages/ViewAllReports'
import ViewSingleReport from './Pages/ViewSingleReport'
import Home from './Pages/Home'
import Navi from './Components/Navi'
import ProtectedRoute from './Components/ProtectedRoute'
import TermsAndConditions from './Components/Terms'
import ViewProject from './Pages/ViewProject' 

function App() {
  const [user, loading, error] = useAuthState(auth)

  return (
    <>
      <BrowserRouter>
        <div className='App'>
          <Navi />
          <Routes>
            <Route path='/login' element={<LogInUser />} />
            <Route path='/terms' element={<TermsAndConditions />} />
            <Route element={<ProtectedRoute user={user} />}>
              <Route path='/' element={<Home />} />
              <Route path='/UserProfile' element={<DisplayProfile />} />
              <Route path='/SelectPosts' element={<UserPosts />} />
              <Route path='/ViewAllReports' element={<UserAIReports />} />
              <Route path='/ViewReport' element={<ViewSingleReport />} />
              <Route path='/EditProject' element={<ViewProject />}/>
            </Route>
          </Routes>
        </div>
      </BrowserRouter>
    </>
  )
}

export default App
