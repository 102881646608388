import { db } from '../Firebase/FirebaseConfig'
import { collectionGroup } from 'firebase/firestore'
import ProjectBox from '../Components/ProjectBox'

const Home = () => {
  const projectsRef = collectionGroup(db, 'projects')

  return <ProjectBox projectsRef={projectsRef} parentComponent={'home'} />
}

export default Home
