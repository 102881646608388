import { db, auth } from '../Firebase/FirebaseConfig'
import { useAuthState } from 'react-firebase-hooks/auth'
import {
  collection,
  query,
  getDocs,
  updateDoc,
  arrayUnion,
  arrayRemove,
  doc
} from 'firebase/firestore'
import { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  Typography,
  Divider,
  Stack,
  Paper,
  Avatar,
  IconButton,
  Tooltip,
  TextField,
  Snackbar
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import SendIcon from '@mui/icons-material/Send'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import DeleteIcon from '@mui/icons-material/Delete'
import '../Styling/Friends.css'

const Friends = ({ userRef, userProfile }) => {
  const [user, loading, error] = useAuthState(auth)
  const [allUsers, setAllUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [searchedValue, setSearchedValue] = useState('')
  const [friends, setFriends] = useState([])
  const [sentRequests, setSentRequests] = useState([])
  const [receivedRequests, setReceivedRequests] = useState([])
  const [editFriends, setEditFriends] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)

  useEffect(() => {
    async function handleAsync() {
      //get all users
      let tmpUsers = []
      let tmpFriends = []
      let tmpSentRequests = []
      let tmpReceivedRequests = []
      const q = query(collection(db, 'users'))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        let data = { ...doc.data(), id: doc.id }
        //If user is a friend, put in friend array
        if (data.friends && data.friends.includes(user.uid)) {
          tmpFriends.push(data)
        } else if (
          userProfile.sentRequests &&
          userProfile.sentRequests.includes(data.id)
        ) {
          tmpSentRequests.push(data)
        } else if (
          userProfile.receivedRequests &&
          userProfile.receivedRequests.includes(data.id)
        ) {
          tmpReceivedRequests.push(data)
        } else if (data.id !== user.uid) {
          //otherwise put user in general users array
          tmpUsers.push(data)
        }
      })
      setAllUsers(tmpUsers)
      setFilteredUsers(tmpUsers)
      setFriends(tmpFriends)
      setSentRequests(tmpSentRequests)
      setReceivedRequests(tmpReceivedRequests)
    }

    handleAsync()
  }, [])

  const request = async (friend) => {
    //Update user account
    await updateDoc(userRef, { sentRequests: arrayUnion(friend.id) })

    //Update friend account
    const friendRef = doc(db, 'users', friend.id)
    await updateDoc(friendRef, { receivedRequests: arrayUnion(user.uid) })

    //Remove from users array
    let tmpUsers = [...allUsers]
    let index = tmpUsers.findIndex((tmpUser) => tmpUser.id === friend.id)
    tmpUsers.splice(index, 1)
    setAllUsers(tmpUsers)
    setFilteredUsers(tmpUsers)
    setSearchedValue('')

    //Add user to friend array
    let tmpSent = [...sentRequests]
    tmpSent.push(friend)
    setSentRequests(tmpSent)

    //Show confirmation
    setShowConfirm(true)
  }

  const handleCloseConfirm = () => {
    setShowConfirm(false)
  }

  const handleEdit = () => {
    setEditFriends(!editFriends)
  }

  const handleSearch = (e) => {
    setSearchedValue(e.target.value)

    let tmpArr = [...allUsers]
    let newArr = tmpArr.filter((tmp) =>
      tmp.name.toLowerCase().includes(e.target.value.toLowerCase())
    )
    setFilteredUsers(newArr)
  }

  const handleDelete = async (friend) => {
    //Update user account
    await updateDoc(userRef, { friends: arrayRemove(friend.id) })

    //Update friend account
    const friendRef = doc(db, 'users', friend.id)
    await updateDoc(friendRef, { friends: arrayRemove(user.uid) })

    //Remove from friends array
    let tmpFriends = [...friends]
    let index = tmpFriends.findIndex((tmpFriend) => tmpFriend.id === friend.id)
    tmpFriends.splice(index, 1)
    setFriends(tmpFriends)

    //Add old friend to user array
    let tmpUsers = [...allUsers]
    tmpUsers.push(friend)
    setAllUsers(tmpUsers)
  }

  const handleAccept = async (request) => {
    //update user account
    await updateDoc(userRef, { receivedRequests: arrayRemove(request.id) })
    await updateDoc(userRef, { friends: arrayUnion(request.id) })

    //update friend account
    const friendRef = doc(db, 'users', request.id)
    await updateDoc(friendRef, { sentRequests: arrayRemove(user.uid) })
    await updateDoc(friendRef, { friends: arrayUnion(user.uid) })

    //Update state
    let tmpFriends = [...friends]
    tmpFriends.push(request)
    setFriends(tmpFriends)

    let tmpReceived = [...receivedRequests]
    let index = tmpReceived.findIndex((item) => item.id === request.id)
    tmpReceived.splice(index, 1)
    setReceivedRequests(tmpReceived)
  }

  const handleReject = async (request) => {
    //update user account
    await updateDoc(userRef, { receivedRequests: arrayRemove(request.id) })

    //update friend account
    const friendRef = doc(db, 'users', request.id)
    await updateDoc(friendRef, { sentRequests: arrayRemove(user.uid) })

    //Update state
    let tmpUsers = [...allUsers]
    tmpUsers.push(request)
    setAllUsers(tmpUsers)
    if (searchedValue === '') {
      setFilteredUsers(tmpUsers)
    }

    let tmpReceived = [...receivedRequests]
    let index = tmpReceived.findIndex((item) => item.id === request.id)
    tmpReceived.splice(index, 1)
    setReceivedRequests(tmpReceived)
  }

  return (
    <Box className='container'>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} className='grid'>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={12}>
              <Paper>
                <Typography variant='h6'>Requests</Typography>

                <Divider />

                <Stack spacing={1}>
                  {receivedRequests.map((request) => {
                    return (
                      <Grid container key={request.id}>
                        <Grid item xs={9} className='request-list'>
                          <Typography style={{ marginLeft: '5px' }}>
                            {request.name}
                          </Typography>
                        </Grid>

                        <Grid item xs={3} className='request-options'>
                          <Tooltip title='Accept Request'>
                            <IconButton
                              color='success'
                              onClick={() => handleAccept(request)}
                            >
                              <CheckIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title='Delete Request'>
                            <IconButton
                              color='error'
                              onClick={() => handleReject(request)}
                            >
                              <DeleteIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    )
                  })}
                </Stack>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6} md={12}>
              <Paper>
                <div className='friends-header'>
                  <Typography className='friends-title' variant='h6'>
                    Friends
                  </Typography>
                  <div className='friends-edit'>
                    <Tooltip title='Edit'>
                      <IconButton onClick={handleEdit}>
                        <EditIcon fontSize='small' />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>

                <Divider />

                <Stack spacing={1}>
                  {friends.map((friend) => {
                    return (
                      <Grid container key={friend.id}>
                        <Grid item xs={2}>
                          <Avatar
                            src={friend['profile-image-url']}
                            style={{ margin: '2px' }}
                          />
                        </Grid>
                        <Grid item xs={8} className='friend-name'>
                          <Typography style={{ marginLeft: '5px' }}>
                            {friend.name}
                          </Typography>
                        </Grid>

                        <Grid item xs={2}>
                          {editFriends ? (
                            <Tooltip title='Delete Friend'>
                              <IconButton onClick={() => handleDelete(friend)}>
                                <ClearIcon fontSize='small' />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                    )
                  })}
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={9}>
          <Paper>
            <Typography variant='h4' sx={{ pt: 1 }}>
              User Search
            </Typography>
            <TextField
              variant='outlined'
              placeholder='e.g. John Smith'
              value={searchedValue}
              sx={{ width: 1 / 2, margin: 2 }}
              onChange={(e) => handleSearch(e)}
            />
            <Grid container spacing={2}>
              {filteredUsers.map((user) => {
                return (
                  <Grid item xs={6} sm={4} md={3} key={user.id}>
                    <Paper elevation={1} className='user-card'>
                      <div className='user-header'>
                        <Avatar
                          src={user['profile-image-url']}
                          style={{ margin: '2px' }}
                        />
                        <div style={{ textAlign: 'left' }}>
                          <Typography>{user.name}</Typography>
                          {user.bio && user.bio !== 'undefined' ? (
                            <Typography variant='caption'>
                              {user.bio}
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <Tooltip title='Send Friend Request'>
                        <IconButton onClick={() => request(user)}>
                          <SendIcon fontSize='small' />
                        </IconButton>
                      </Tooltip>
                    </Paper>
                  </Grid>
                )
              })}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        open={showConfirm}
        autoHideDuration={6000}
        onClose={handleCloseConfirm}
        message='Request Sent'
        action={
          <>
            <IconButton
              size='small'
              aria-label='close'
              color='inherit'
              onClick={handleCloseConfirm}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </>
        }
      />
    </Box>
  )
}

export default Friends
