//saves info as a new document in the firebase "reports" collection
import React, {useState, useEffect} from "react";
import {useAuthState}  from "react-firebase-hooks/auth";

import{db, auth} from '../Firebase/FirebaseConfig';
import { collection, addDoc, serverTimestamp} from "firebase/firestore";


const SaveReport = async (essay, descriptions, captionPairs, tempTitle) => {    
    const user = auth.currentUser;
    let docID;

    try{
        const reportRef = await addDoc(collection(db, "reports"), {
            currentVer: 0,
            descriptions: descriptions,
            // essay: essay,
            // figures: captionPairs,
            versions: [{essay: essay, figures: captionPairs}],
            'user-id': user.uid,
            'date-created': serverTimestamp(),
            title: tempTitle,
        });
        //console.log("Document ID:", reportRef.id);
        docID = reportRef.id;

    } catch(err) {
        console.log("error:", err)
    }
    return(docID);
};
export default SaveReport;