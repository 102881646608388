//Updates existing report in Firebase
import React, {useState, useEffect} from "react";
import{db, auth} from '../Firebase/FirebaseConfig';
import { doc, updateDoc, serverTimestamp, arrayUnion} from "firebase/firestore";


const AddVersion = async (essayResponse, pairsResponse,  docID) => {    
    console.log("Add Version.js")

    try{
        const reportRef = doc(db, "reports", docID);
        // console.log(reportRef.data())
        await updateDoc(reportRef, { 
            // versions: [...verHistory, {essay: essayResponse, figures: pairsResponse}],
            versions: arrayUnion({essay: essayResponse, figures: pairsResponse}),
            'date-updated': serverTimestamp()
        });
        console.log("ID of updated document:", reportRef.id)

    } catch(err) {
        console.log("error:", err)
    }
    return({essay: essayResponse, figures: pairsResponse})
};
export default AddVersion;