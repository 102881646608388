import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Avatar,
  TextField,
  Typography,
  Divider,
  IconButton,
  CardActions,
  Button,
  // Popover,
  Menu,
  useMediaQuery,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material'
import { MoreHoriz, Edit } from '@mui/icons-material'

const Report = (props) => {
  const todaysDate = new Date().toDateString()
  const [errors, setErrors] = useState([])
  const [openEditCaption, setOpenEditCaption] = useState(false)
  const [editCaption, setEditCaption] = useState('')
  const [captionIndex, setCaptionIndex] = useState('')

  const handleEditCaption = (item, index) => {
    setOpenEditCaption(true)
    setEditCaption(item.caption)
    setCaptionIndex(index)
  }

  const handleCloseEditCaption = () => {
    setOpenEditCaption(false)
    setEditCaption('')
    setCaptionIndex('')
  }

  const mobile = useMediaQuery('(max-width:600px)')
const showVersionControlButton = useMediaQuery('(max-width:900px)')
  const isSingleImage = () => {
    if (props.report.figures?.length > 1) {
      return false
    } else {
      return true
    }
  }

  return (
    <>
      <Card sx={{ ...props.sx }}>
        <CardHeader
          avatar={
            <Avatar
              src={props.pictureUrl}
              sx={{ width: 'auto', height: '50px' }}
            />
          }
          title={props.user}
          titleTypographyProps={{ align: 'left' }}
          // subheader={project.title}
          subheaderTypographyProps={{ align: 'left' }}
          style={{ height: '50px' }}
          action={props.report.date || props.date}
          sx={{
            paddingLeft: props.singleView && mobile ? '40px' : null,
            borderBottom: '1px solid grey',
          }}
        />
        <CardContent>
          {props.isEdit ? (
            <TextField
              variant='standard'
              inputProps={{ style: { textAlign: 'center' } }}
              fullWidth
              id='report-title'
              placeholder='Report Title'
              name='reportTitle'
              className='defaultBox'
              value={props.title || ''}
              onChange={(e) => props.handleTitleChange(e.target.value)}
              error={errors.includes('reportTitle')}
              sx={{marginBottom: '20px' }}
            />
          ) : (
            <>
              <Typography sx={{ fontWeight: 'bold', fontSize: '24px'}}>
                {props.report.title || 'No Title'}
              </Typography>
              <Divider sx={{marginBottom: '20px' }}/>
            </>
          )}
          <Dialog open={openEditCaption} onClose={handleCloseEditCaption}>
            <DialogTitle>Edit Caption</DialogTitle>
            <DialogContent>
              <TextField
                // variant='standard'
                // inputProps={{ style: { textAlign: 'center' } }}
                fullWidth
                multiline
                id='caption-edit'
                sx={{ minWidth: '500px' }}
                value={editCaption || ''}
                onChange={(e) => setEditCaption(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  props.handleChange('caption', editCaption, 0)
                  handleCloseEditCaption()
                }}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
          <ImageList
            sx={{
              width: isSingleImage() ? { md: '50%' } : null,
              margin: 'auto auto'
            }}
            gap={10}
            variant='masonry'
            cols={mobile || isSingleImage() ? 1 : 2}
          >
            {props.report.figures?.map((item, index) => {
              return (
                <ImageListItem
                // className='post_img'
                // onClick={() => {
                //   selectProject(project, index)
                // }}
                >
                  <img
                    src={`${item['image-url']}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    srcSet={`${item['image-url']}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt='An image of a project'
                    loading='lazy'
                  />
                  <ImageListItemBar
                    // title={todaysDate}
                    subtitle={item.caption}
                    actionIcon={
                      props.isEdit ? (
                        <IconButton
                          sx={{ color: 'white' }}
                          onClick={() => handleEditCaption(item, index)}
                        >
                          <Edit />
                        </IconButton>
                      ) : null
                    }
                    sx={{
                      '& .MuiImageListItemBar-subtitle': {
                        whiteSpace: 'normal',
                        textAlign: 'left'
                      },
                      '& .MuiImageListItemBar-title': {
                        fontSize: '10px',
                        textAlign: 'left'
                      }
                    }}
                  />
                </ImageListItem>
              )
            })}
          </ImageList>
          {props.isEdit ? (
            <TextField
              name='report'
              className='defaultBox'
              multiline
              fullWidth
              value={props.report.essay}
              onChange={(e) => props.handleChange('essay', e.target.value)}
              error={errors.includes('essay')}
            />
          ) : (
            <>
              <Typography align='left'>{props.report.essay}</Typography>
            </>
          )}
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          {props.isEdit ? (
            <>
            {showVersionControlButton ? <Button onClick={() => props.handleShowVersions()}>Version Control</Button> : null}
            <Button onClick={() => props.handleSave()}>Save</Button>
            </>
          ) : (
            <>
              <Button onClick={() => props.handleEditToggle()}>Edit</Button>
              { props.addSlide ? ( 
                <>
                  <Button onClick={() => props.handleSubmitToSlides()}>Add To Presentation</Button>
                </>
                ):(
                <> </>
                ) 
              }
              {/* <Menu
                id='edit-popover'
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={closePopover}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
              >
                {!props.singleView ? (
                  <MenuItem
                    onClick={() => {
                      props.handleViewReport()
                      closePopover()
                    }}
                  >
                    Go to Report
                  </MenuItem>
                ) : (
                  null
                )}{' '}
                <MenuItem
                  onClick={() => {
                    props.handleEditToggle()
                    closePopover()
                  }}
                >
                  Edit Report
                </MenuItem>
              </Menu>
              <IconButton
                onClick={
                  openPopover
                  // props.handleEditToggle()
                }
              >
                <MoreHoriz />
              </IconButton> */}
            </>
          )}
        </CardActions>
      </Card>
    </>
  )
}

export default Report
