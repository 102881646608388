//select post to be generated into an AI report
import React, { useState, useEffect, useCallback } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate, useLocation } from 'react-router-dom'
import { db, auth } from '../Firebase/FirebaseConfig'
import '../Styling/Project.css'
import '../Styling/Default.css'
import {
  Button,
  Divider,
  TextField,
  Paper,
  IconButton,
  Box,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import UpdateProject from '../Components/UpdatePost'

const ViewProject = () => {
  // console.log("EditUserProject:")
  const navigate = useNavigate()
  const { id, title, posts, date } = useLocation().state
  const [user, loading, error] = useAuthState(auth)
  
  const [projectID, setProjectID] = useState(id)
  const [projectTitle, setProjectTitle] = useState(title)
  const [projectPosts, setProjectPosts] = useState(posts)
  const [newProjectPosts, setNewProjectPosts] = useState(null)
  
  const mobile = useMediaQuery('(max-width:600px)')
  const [errors, setErrors] = useState([])
  const [isEdited, setIsEdited] = useState(false)
  const [isUpdated, setisUpdated] = useState(false)
  const [isChanged, setIsChanged]= useState(false)
  const [isDateChanged, setIsDateChanged] = useState(false)

  const handleTitleChange = (value) => {
    setProjectTitle(value)
    setIsEdited(true)
  }

  const handleDescriptionChange = (index, value) => {    
    let tmp = [...projectPosts]
    tmp[index].description = value  
    setProjectPosts(tmp)
    setIsEdited(true)
  }

  const handleDateChange = () => {
    let tmp = [...projectPosts]
    {projectPosts.map((post, index) => {
      let TimeStamp = post['created-date']
      tmp[index]['created-date'] = new Date(TimeStamp.seconds*1000)
    })}
    setNewProjectPosts(tmp)
    setIsDateChanged(true)
  }

  const handleSave = () => {
    if(isDateChanged === false){
      handleDateChange()
    }else{
      UpdateProject(projectTitle, newProjectPosts, projectID).then((resp) =>{
        setisUpdated(resp)
      })
    }
  }

  const handleBackButton = () => {
    if(isEdited === true){      //show warning
      const resp = window.confirm("You have edited this project. Leaving this page will clear your changes. Are you sure you want to proceed?");
      if(resp == true){     
        setisUpdated(true)
        setIsEdited(false)
      }
    }else{
      navigate('/UserProfile', { state: { prevTab: 0 } })
    }
  }

  useEffect(() => {
    if(isDateChanged === true){
      handleSave()
    }
  },[isDateChanged])

  useEffect(() => {
    if(isUpdated === true){
      navigate('/UserProfile', { state: { prevTab: 0 } })
    }
  }, [isUpdated])

  return (
  <div>
      <Box 
        sx={{
          display: 'grid',
          width: '100%',
          gridTemplateColumns: { xs: '10% auto 10%', sm: '15% 80% 15%', lg: '25% 50% 13% 12%' }, 
          marginTop: mobile ? '0px' : '20px',
          marginBottom: '100px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gridRow: '1/2',
            gridColumn: '1/2',
          }}
        >
          <IconButton
            onClick={() => handleBackButton()}
            sx={{ margin: mobile ? '20px 0 0 0' : '0 0 0 20px' }}
          >
            <ArrowBack />
          </IconButton>
        </Box>
        
      <div className='project_container'>        
        <Paper className='project'>

          <div className='project_header'>
            <div className='project_info'>
              <div className='project_details_profile'> 
                <TextField
                  variant='outlined'
                  className='projectTitle'
                  inputProps={{ style: { textAlign: 'left', fontSize: '25'} }}
                  multiline
                  id='title-edit'
                  minRows={1}
                  maxRows={2}
                  value={projectTitle}
                  onChange={(e) => handleTitleChange(e.target.value)}
                />
              </div>
            </div>

            <div className='project_date'>
              <Typography align='left' variant='h6'>
                {date}
              </Typography>
            </div>
          </div>

          <Divider />

          <div className='project_body'>
            {projectPosts.map((post, index) => {
              return (
                <div className='post_container'>
                  <img
                    src={`${post['image-url']}`}
                    alt='An image of a project'
                    loading='lazy'
                    className='post_img'
                  />

                  <TextField
                    variant='outlined'
                    className='postDescription'
                    inputProps={{ style: { textAlign: 'left'} }}
                    fullWidth
                    multiline
                    minRows={9}
                    maxRows={9}
                    value={post.description}
                    onChange={(e) => handleDescriptionChange(index, e.target.value)}
                  />
                </div>
              )
            })}
          </div>
          
          <Divider />

        </Paper>

        <Box sx={{ display:'flex' , justifyContent: 'flex-end' }} >
          <Button onClick={() => handleSave()}>Save</Button>
        </Box>
      
      </div>
    </Box>
  </div>
  )
}

export default ViewProject
