//Updates existing posts in Firebase
import React, {useState, useEffect} from "react";
import{db, auth} from '../Firebase/FirebaseConfig';
import { doc, collection, updateDoc } from "firebase/firestore";

const UpdateProjectVisibility = async (value, project) => {
  const docId = project.id        
  const newAlignment = value     

  const user = auth.currentUser;    
  const userRef = doc(db, 'users', user.uid)
  const projectsRef = collection(userRef, 'projects')
  const docRef = doc(projectsRef, docId)

  try{
      await updateDoc(docRef, { 
          visibility : newAlignment, 
      });
  } catch(err) {
      console.log("error:", err)
  }

  return(docId)
    
};
export default UpdateProjectVisibility;
