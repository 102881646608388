import React from 'react';
import { Box } from '@mui/material';

const ContentContainer = (props) => {
    return (
        <Box sx={{width: {xs: '100%', sm: '70%', lg: '50%'}, maxWidth: '800px', margin: 'auto auto'}}>
            {props.children}
        </Box>
    );
}

export default ContentContainer;
