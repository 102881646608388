//view of all AI generated reports
import React, { useState, useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import '../Styling/Project.css'
import '../Styling/Default.css'

import { db, auth, firebaseConfig } from '../Firebase/FirebaseConfig'
import {
  addDoc,
  getDocs,
  collection,
  doc,
  orderBy,
  serverTimestamp,
  query,
  where,
  updateDoc,
  getDoc
} from 'firebase/firestore'

import {
  Grid,
  Alert,
  Typography,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Stepper,
  Step,
  StepLabel,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
  Box,
  List,
  ListItem,
  Card,
  CardContent,
  CardMedia,
  CardHeader,
  Paper,
  Checkbox,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Snackbar,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
  Badge,
  Tabs,
  Tab
} from '@mui/material'
import ScrollToTop from 'react-scroll-to-top'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CloseIcon from '@mui/icons-material/Close'
import EditReportView from '../Components/EditReportView'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import ViewPosts from '../Components/ViewPosts'

import {
  createPresentation,
  addMultipleSlides,
  addMultiplePostSlides
} from '../GoogleAPIs/ScriptAPI';

const UserAIReports = () => {
  //const currUser = auth.currentUser
  const [user, loading, error] = useAuthState(auth)
  let tempTitle
  const navigate = useNavigate()
  const presentationListTemp = []
  const [presentationList, setPesentationList] = useState([])
  // const [open, setOpen] = React.useState(false)
  //const [openOther, setOpenOther] = React.useState(false)
  // const [showInfo, setShowInfo] = useState(false)
  // const [presentationSelected, setPesentationSelected] = React.useState('')
  // const [showSelection, setShowSelection] = useState(false)
  // const [showCheckbox, setShowCheckbox] = useState(false)
  // const [isCheck, setIsCheck] = useState([])
  //------------------------------------------------------------------------
  //Google Identity & gapi
  // const [userExists, setUserExists] = useState(false)
  const [tokenAuth, setTokenAuth] = useState({})
  var tokenClient
  const [gapiInited, setGapiInited] = useState(false)
  const [gisInited, setGisInited] = useState(false)
  const [apisLoaded, setApisLoaded] = useState(false)

  const steps = [
    'Sign in with Google',
    'Select Presentation',
    'Select Reports',
    'Add Slides'
  ]
  const { prevStep, fromViewReport, prevPresentation, prevTemplate } = useLocation().state
  //const [activeStep, setActiveStep] = useState(0)
  const [activeStep, setActiveStep] = useState(prevStep)
  const [alignment, setAlignment] = useState('existing')
  const [selectedPresentation, setSelectedPresentation] = useState('')
  const [presentationTitle, setPresentationTitle] = useState(null)
  const [allReports, setAllReports] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedReport, setSelectedReport] = useState(null)
  const [addedReports, setAddedReports] = useState(new Map())
  const [checked, setChecked] = useState(false)
  const [createLoading, setCreateLoading] = useState(false)
  // const [successMessage, setSuccessMessage] = useState(false)
  const [reportDialog, setReportDialog] = useState(false)
  const [editDialog, setEditDialog] = useState(false)
  const [trigger, setTrigger] = useState(false)
  const [submitTrigger, setSubmitTrigger] = useState(false)

  const mobile = useMediaQuery('(max-width:600px)')
  const [selectedTemplate, setSelectedTemplate] = React.useState()
  const [newPresLoading, setNewPresLoading] = useState(false)

  const [currTab, setCurrTab] = useState(0)
  const [dataSelected, setDataSelected] = useState(false);
  const [currTemplate, setCurrTemplate] = useState()

  const [templateList] = useState([
    { 
      label: "VRC Notebook",
      type: "VRC",
      presentationId: '1pqv4JF1CY_6qzGIgPOE4nKg6yxE49y0OLB5Gd_ZifFo', //presentation templates
      copyId: '1dGqpCyqE8pQv4x3mIabCvtOZb_FBnU1k97cAdcuh3-E', //copy this presentation
    },
    {
      label : 'VIQC Notebook',
      type: 'VIQC',
      presentationId : '1TFK73OcndMjElzanOorvaMG3NKOq4tYdu1VHC9YN4fc',  //presentation templates
      copyId: '1JdjrLWrgwprZYvkZK8_2RzcDICGt5nRY1cQi_3QfFCw'  //copy this presentation
    }
  ]);

  const styles = {
    stepperBox: {
      margin: '0 auto',
      width: { xs: '90%', sm: '70%', md: '50%' },
      border: '1px solid gray',
      backgroundColor: 'white'
    },
    mobileTabs: {
      minHeight: '0px',
      marginTop: '5px',
      '.MuiTab-root': { minHeight: '0px', minWidth: '0px', padding: '8px 10px' }
    }
  }

  const handleAlignmentChange = (event, newAlignment) => {
    setSelectedPresentation('')
    setPresentationTitle(null)
    //setSelectedTemplate('')
    setAlignment(newAlignment)
  }

  useEffect(() => {
    let tmpReports = [...addedReports]
    let index = tmpReports.findIndex((elm) => elm.id === selectedReport.id)
    if (index === -1) {
      setChecked(false)
    }
  }, [selectedReport, addedReports])

  useEffect(() => {
    if(fromViewReport == true){
      setSelectedPresentation(prevPresentation)
      setSelectedTemplate(prevTemplate)
      // handleDisplayPresentations()
      setActiveStep(prevStep)
    }
  },[fromViewReport])

  useEffect(() => {
    if(activeStep === 2 && prevStep === 3){
      handleDisplayPresentations()
    }
  },[activeStep])

  useEffect(() => {
    /* global google */

    //handles loading api library
    handleGapi()

    //handles authorization
    handleGis()
  }, [])

  function initClient() {
    gapi.client
      .init({
        //
      })
      .then(function(){
        gapi.client.load('https://slides.googleapis.com/$discovery/rest?version=v1')
      })
      .then(function(){
        gapi.client.load('https://script.googleapis.com/$discovery/rest?version=v1')
        setGapiInited(true)
      })
  }

  const handleGapi = () => {
    /* global gapi*/

    try {
      gapi.load('client', initClient)
    } catch (err) {
      console.log('err:', err)
    }
  }

  const handleGis = () => {
    //gis init
    tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: firebaseConfig.clientId,
      scope: 'https://www.googleapis.com/auth/presentations https://www.googleapis.com/auth/drive',
      prompt: 'consent',
      callback: ''
    })
    setGisInited(true)
    setTokenAuth(tokenClient)
  }

  useEffect(() => {
    if (gapiInited == true && gisInited == true) {
      setApisLoaded(true)
    }
  }, [gapiInited, gisInited])

  async function handleSignIn() {
    try {
      tokenAuth.callback = (response) => {
        if (response.error !== undefined) {
          console.log('response.error:', response.error)
        }
        // setUserExists(true)
        setActiveStep(1)
      }

      if (gapi.client.getToken() === null) {
        tokenAuth.requestAccessToken({ prompt: 'consent' })
      } else {
        tokenAuth.requestAccessToken({ prompt: '' })
      }
    } catch (err) {
      console.log(err)
    }
  }

  async function revokeToken() {
    return new Promise((resolve) => {
      let cred = gapi.client.getToken()
      if (cred !== null) {
        google.accounts.oauth2.revoke(cred.access_token, () => {
          console.log('Revoked credentials')
        })
        gapi.client.setToken('')
        resolve()
      }
    })
  }

  const handleGoogleSignOut = () => {
    // setUserExists(false)
    revokeToken()
  }

  //-----------

  const getReports = async () => {
    setIsLoading(true)
    const reportsRef = collection(db, 'reports')
    const q = query(
      reportsRef,
      where('user-id', '==', user.uid),
      orderBy('date-created', 'desc')
    )
    const reports = []
    let reportDate
    try {
      const reportSnapshot = await getDocs(q)
      reportSnapshot.forEach((doc) => {
        if (doc.data()['date-created']) {
          reportDate = doc.data()['date-created'].toDate().toLocaleDateString()
        } else {
          reportDate = 'No Date'
        }
        if (doc.data().title == null) {
          tempTitle = 'No Title'
        } else {
          tempTitle = doc.data().title
        }
        let report
        if (typeof doc.data()['currentVer'] !== 'undefined') {
          let currentData = doc.data().versions[doc.data().currentVer]
          report = {
            id: doc.id,
            title: tempTitle,
            date: reportDate,
            essay: currentData.essay,
            figures: currentData.figures,
            descriptions: doc.data().descriptions
          }
        } else {
          report = {
            id: doc.id,
            title: tempTitle,
            date: reportDate,
            essay: doc.data().essay,
            figures: doc.data().figures,
            descriptions: doc.data().descriptions
          }
        }
        reports.push(report)
      })
      setIsLoading(false)
      setAllReports(reports)
      setActiveStep(2)
    } catch (err) {
      console.log('error:', err)
    }
  }

  //get presentations from firebase
  const GetPresentations = async () => {
    const userRef = doc(db, 'users', user.uid)
    const presentationRef = collection(userRef, 'presentations')
    const q = query(presentationRef, orderBy('date-created', 'desc'))
    const snapshot = await getDocs(q)

    if (!snapshot.empty) {
      try {
        snapshot.forEach((doc) => {
          const presentationInfo = {
            docId: doc.id,
            title: doc.data().title,
            presId: doc.data().id
          }
          presentationListTemp.push(presentationInfo)
        })
        setPesentationList(presentationListTemp)
      } catch (err) {
        console.log('error:', err)
      }
    }
  }

  const getDefaultValue = async () => {
    const userRef = doc(db, 'users', user.uid)
    const userSnapshot = await getDoc(userRef)
    if(userSnapshot.data().defaultTemplate){
      const currentDefault = userSnapshot.data().defaultTemplate;
      setCurrTemplate(currentDefault)
      if(currentDefault === 'VRC'){
        setSelectedTemplate(templateList[0])
      }else{
        setSelectedTemplate(templateList[1])
      }
      return  
    }else{
      setCurrTemplate(null)
      setSelectedTemplate('')
    }
  }

  //save presentation to firebase
  const savePresentation = async (presId) => {
    const user = auth.currentUser
    const userRef = doc(db, 'users', user.uid)
    let docID
    return new Promise((resolve) => {
      try {
        const presentationRef = addDoc(collection(userRef, 'presentations'), {
          'date-created': serverTimestamp(),
          id: presId,
          title: presentationTitle
        })
        docID = presentationRef.id
        resolve(docID)
      } catch (err) {
        console.log('error:', err)
      }
      return docID
    })
  }

  // update default template to firebase
  const updateDefault = async () => {
    const userRef = doc(db, 'users', user.uid)
    try{
      await updateDoc(userRef, { 
        defaultTemplate : selectedTemplate.type,
      });
    } catch(err) {
      console.log("error:", err)
    }
  }

  const checkDefault = () => {
    if(currTemplate === null){
      updateDefault()
    }else{
      if (selectedTemplate.type !== currTemplate){
        updateDefault()
      }else{
        return      
      }
    }
  }

  useEffect(() => {
    getDefaultValue()
  }, [])

  //Update page after edit
  useEffect(() => {
    async function handleAsync() {
      const reportRef = doc(db, 'reports', selectedReport.id)
      const reportSnap = await getDoc(reportRef)
      let reportObj = { ...reportSnap.data() }
      let tmpReport
      if (typeof reportObj.currentVer !== 'undefined') {
        let currentData = reportObj.versions[reportObj.currentVer]
        tmpReport = {
          id: reportObj.id,
          title: reportObj.title,
          date: reportObj['date-created'].toDate().toLocaleDateString(),
          essay: currentData.essay,
          figures: currentData.figures,
          descriptions: reportObj.descriptions
        }
      } else {
        tmpReport = {
          id: reportObj.id,
          title: reportObj.title,
          date: reportObj['date-created'].toDate().toLocaleDateString(),
          essay: reportObj.essay,
          figures: reportObj.figures,
          descriptions: reportObj.descriptions
        }
      }
      tmpReport.id = selectedReport.id

      let tmpAllReports = [...allReports]
      let tmpIndex = tmpAllReports.findIndex(
        (elm) => elm.id === selectedReport.id
      )
      tmpAllReports[tmpIndex] = tmpReport
      setAllReports(tmpAllReports)

      setSelectedReport(tmpReport)
    }
    if (selectedReport) {
      handleAsync()
    }
  }, [trigger])

  useEffect(() => {
    if (user) {
      GetPresentations()
    }
  }, [user, submitTrigger])

  // const [expanded, setExpanded] = React.useState(false)
  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false)
  // }

  // const handleEditClick = (report) => {
  //   let { descriptions, ...rest } = report
  //   navigate('/ViewReport', {
  //     state: {
  //       report: rest,
  //       edit: true,
  //       selectedData: descriptions
  //     }
  //   })
  // }

  // const handleClose = () => {
  //   setOpen(false)
  //   setOpenOther(false)
  // }

  // const viewReport = (report, isEdit) => {
  //   let { descriptions, ...rest } = report
  //   navigate('/ViewReport', {
  //     state: {
  //       report: rest,
  //       edit: isEdit,
  //       selectedData: descriptions
  //     }
  //   })
  // }

  //-----
  // const handleSetButton = async () => {
  //   setShowInfo(true)
  //   setOpenOther(true)
  // }

  // const handleClearButton = async () => {
  //   setShowSelection(false)
  //   setPesentationSelected('')
  //   setShowCheckbox(false)
  // }

  const handleSelectPresentation = (event) => {
    setSelectedPresentation(event.target.value)
  }

  const handleSelectTemplate = (event) => {
    setSelectedTemplate(event.target.value);
  }

  const handleCreatePresentation = async () => {
    let presId
    setNewPresLoading(true) 
    await createPresentation(presentationTitle, selectedTemplate).then((response) => {
      presId = response
      savePresentation(presId)
      let tmpPresentation = {
        presId: presId,
        title: presentationTitle
      }
      setSelectedPresentation(tmpPresentation)
      setNewPresLoading(false)
    })
  }

  const handleNewTitle = (e) => {
    if (e.target.value === '') {
      setPresentationTitle(null)
    } else {
      setPresentationTitle(e.target.value)
    }
  }

  const handleDisplayPresentations = async () => {
    // setSuccessMessage(false)
    await getReports()
    checkDefault()
    if (presentationTitle) {
      await handleCreatePresentation()
    }
  }

  const selectReport = (selection) => {
    setSelectedReport(selection)
    if (mobile) {
      setReportDialog(true)
    }
  }

  const closeDialog = () => {
    setReportDialog(false)
  }

  const closeEditDialog = async () => {
    setEditDialog(false)
  }
  const handleCheck = () => {
    if (addedReports.has(selectedReport.id)) {
      setAddedReports((reports) => {
        reports.delete(selectedReport.id, selectedReport)
        return new Map(reports)
      })
    } else {
      setAddedReports(
        (reports) => new Map(reports.set(selectedReport.id, selectedReport))
      )
    }
  }

  const handleDeselect = () => {
    setAddedReports([])
  }

  const handleSubmit = async () => {
    setCreateLoading(true)
    const presID = selectedPresentation.presId;
    await addMultipleSlides(addedReports, presID, selectedTemplate).then(
      (response) => {
        setAddedReports(new Map())
        setActiveStep(3)
        setCreateLoading(false)
        // setSuccessMessage(true)
        setSelectedReport(null)
        //Trigger if new presentation was created to update list
        if (presentationTitle) {
          setSubmitTrigger(!submitTrigger)
          setPresentationTitle(null)
        }
        //setSelectedPresentation('')
        //setSelectedTemplate('')
      }
    )
  }

  const handlePostsSubmit = async (value) => {
    setCreateLoading(true)
    const presID = selectedPresentation.presId;

    await addMultiplePostSlides(value, presID, selectedTemplate).then(
      (response) => {
        setDataSelected(false)
        setActiveStep(3)
        setCreateLoading(false)
        
        if (presentationTitle) {
          setSubmitTrigger(!submitTrigger)
          setPresentationTitle(null)
        }
      }
    )
  }

  const handleReportEdit = () => {
    setEditDialog(true)
  }

  const exitRoute = () => {
    navigate('/')
  }

  const SnackbarMessage = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          width: '100%',
          height: '50px'
        }}
      >
        <Button
          variant='contained'
          onClick={handleDeselect}
          disabled={createLoading}
        >
          Deselect All
        </Button>
        {newPresLoading ? (
          <Typography style={{ margin: 'auto 10px' }}>Creating Presentation . . .</Typography>
        ) 
        : createLoading ? (
          <Typography style={{ margin: 'auto 10px' }}>Loading</Typography>
        ) : (
          <Typography
            style={{
              color: 'white',
              margin: 'auto 10px'
            }}
          >
            {addedReports.size}{' '}
            {addedReports.size === 1 ? 'Report ' : 'Reports '}
            Selected
          </Typography>
        )}

        <Button
          variant='contained'
          onClick={handleSubmit}
          disabled={createLoading || newPresLoading}
        >
          Add to Presentation
        </Button>
      </div>
    )
  }

  // const handleClick = (e) => {
  //   const { value, checked } = e.target
  //   setIsCheck([...isCheck, value])
  //   if (!checked) {
  //     setIsCheck(isCheck.filter((item) => item !== value))
  //   }
  // }

  // const PresentationSelection = () => {
  //   return (
  //     <div className='set_presentation'>
  //       <div className='alignOptions'>
  //         Select Existing
  //         <div className='selectMenu'>
  //           <div>
  //             <FormControl sx={{ m: 1, minWidth: 160, maxWidth: 160 }}>
  //               <InputLabel id='select-by-title'>Select Title</InputLabel>
  //               <Select
  //                 className='dropDown'
  //                 labelId='select-by-title'
  //                 value={presentationSelected}
  //                 onChange={handleSelectPresentation}
  //                 defaultValue={''}
  //               >
  //                 <MenuItem value=''>
  //                   {' '}
  //                   <em>None</em>
  //                 </MenuItem>
  //                 {presentationList.map((item) => (
  //                   <MenuItem value={item}> {item.title} </MenuItem>
  //                 ))}
  //               </Select>
  //             </FormControl>
  //           </div>
  //         </div>
  //       </div>
  //       <div className='divOR'>OR</div>
  //       <div className='alignOptions'>
  //         Create New
  //         <div className='responseTemp'>
  //           <TextField
  //             name='report'
  //             className='defaultBox'
  //             value={newTitle}
  //             placeholder='Add Title'
  //             onChange={(e) => handleNewTitle(e, setNewTitle)}
  //           />
  //           <Button
  //             variant='contained'
  //             className='resp_btn'
  //             onClick={handleCreatePresentation}
  //           >
  //             {' '}
  //             Create{' '}
  //           </Button>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }
  // console.log(allReports)
  // const DisplayReports = () => {
  //   return (
  //     <div>
  //       {allReports.map((item, index) => {
  //         return (
  //           <Accordion defaultExpanded={true}>
  //             <AccordionSummary expandIcon={<ExpandMoreIcon />}>
  //               {showCheckbox == true ? (
  //                 <CheckBox
  //                   type='checkbox'
  //                   value={item.id}
  //                   handleClick={handleClick}
  //                   isChecked={isCheck.includes(item.id)}
  //                 />
  //               ) : (
  //                 <></>
  //               )}

  //               <Typography
  //                 sx={{ width: '92%', flexShrink: 0, textAlign: 'left' }}
  //               >
  //                 <b>{item.title}</b>
  //               </Typography>
  //               <Typography sx={{ color: 'text.secondary' }}>
  //                 {item.date}
  //               </Typography>
  //             </AccordionSummary>
  //             <AccordionDetails>
  //               <div className='essay'>
  //                 <div>{item.essay}</div>
  //               </div>

  //               <div style={{ backgroundColor: 'lightblue', padding: '10px' }}>
  //                 <div className='figures_label'>
  //                   <b>Figures:</b>
  //                 </div>
  //                 <div>
  //                   <Grid container spacing={1}>
  //                     {item.figures?.map((figure, index) => {
  //                       return (
  //                         <Grid
  //                           lg={3}
  //                           style={{ margin: '10px auto', display: 'block' }}
  //                         >
  //                           <div key={index} className='figures'>
  //                             <div style={{ backgroundColor: 'white' }}>
  //                               <img
  //                                 className='figure_images'
  //                                 src={figure['image-url']}
  //                               />
  //                             </div>

  //                             <div className='figure_captions'>
  //                               {figure.caption}
  //                             </div>
  //                           </div>
  //                         </Grid>
  //                       )
  //                     })}
  //                   </Grid>
  //                 </div>
  //               </div>

  //               <div>
  //                 <Button onClick={() => viewReport(item, true)}>EDIT</Button>
  //                 <Button
  //                   onClick={() =>
  //                     // viewReport(item.id, item.title, item.essay, item.figures)
  //                     viewReport(item, false)
  //                   }
  //                 >
  //                   {' '}
  //                   Print View
  //                 </Button>
  //                 {showSelection ? (
  //                   <Button
  //                     onClick={() =>
  //                       handleAddSlide(
  //                         item.title,
  //                         item.essay,
  //                         item.figures,
  //                         presentationSelected.presId
  //                       )
  //                     }
  //                   >
  //                     {' '}
  //                     Add as Slide{' '}
  //                   </Button>
  //                 ) : (
  //                   <> </>
  //                 )}
  //               </div>
  //             </AccordionDetails>
  //           </Accordion>
  //         )
  //       })}
  //     </div>
  //   )
  // }

  const handleTabChange = (event, newValue) => {
    if(dataSelected == true){
      //show warning that posts selected will be lost
      const resp = window.confirm("You have selected posts. Leaving this page will deselect your current selections. Are you sure you want to proceed?");
      if(resp == true){     
        setDataSelected(false)
        setCurrTab(newValue)
      }
    }else{
      //continue to next tab
      setCurrTab(newValue)
    }
  }

  const ReportDetail = () => {
    return (
      <Box>
        {selectedReport ? (
          <Paper elevation={0}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <div style={{ display: 'flex' }}>
                <Checkbox
                  checked={addedReports.has(selectedReport.id)}
                  onChange={handleCheck}
                />
                <Typography style={{ margin: 'auto 10px' }}>
                  {selectedReport?.title}
                </Typography>
              </div>
              <Typography style={{ margin: 'auto 10px' }}>
                {selectedReport?.date}
              </Typography>
            </div>

            <Divider />

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={handleReportEdit}>Edit</Button>
            </div>

            <div>
              <Typography align='left' style={{ margin: '10px 30px' }}>
                {selectedReport?.essay}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                overflow: 'hidden'
              }}
            >
              <ImageList
                style={{
                  display: 'flex',
                  flexWrap: 'noWrap',
                  flexDirection: 'row'
                }}
                cols={2.5}
              >
                {selectedReport?.figures.map((figure, index) => {
                  return (
                    <ImageListItem>
                      <img
                        src={`${figure['image-url']}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        srcSet={`${figure['image-url']}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        alt='An image of a report'
                        loading='lazy'
                        style={{ width: '400px' }}
                      />
                      <ImageListItemBar
                        subtitle={figure.caption}
                        sx={{
                          '& .MuiImageListItemBar-subtitle': {
                            whiteSpace: 'normal',
                            textAlign: 'left'
                          },
                          '& .MuiImageListItemBar-title': {
                            fontSize: '10px',
                            textAlign: 'left'
                          }
                        }}
                      />
                    </ImageListItem>
                  )
                })}
              </ImageList>
            </div>
          </Paper>
        ) : (
          <h1>No Report Selected</h1>
        )}
      </Box>
    )
  }

  const handleDataChange = (value) => {
    setDataSelected(value)
  }

  return (
    <div className='wrapper'>
      <div style={{ margin: 'auto', width: '80%' }}>
        <Typography align='center' variant='h4' style={{ margin: '10px 0' }}>
          Generate Presentation
        </Typography>
        <Stepper activeStep={activeStep} style={{ margin: '10px 0' }}>
          {steps.map((label, index) => {
            const stepProps = {}
            const labelProps = {}
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </div>

      <Divider style={{ margin: '10px 0' }} />

      {activeStep === 0 ? (
        <Box sx={{ ...styles.stepperBox }}>
          <div style={{ margin: '10px' }}>
            <Typography variant='h6' align='left'>
              Step 1: Sign in with Google
            </Typography>
            <Alert severity='info' style={{ margin: '5px' }}>
              Important! Sign in with the same email you used for your Edorsum
              account
            </Alert>
            {apisLoaded ? (
              <div
                style={{
                  margin: '5px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end'
                }}
              >
                <Button variant='contained' onClick={handleSignIn}>
                  Sign In with Google
                </Button>
              </div>
            ) : (
              <> </>
            )}
          </div>
        </Box>
      ) : (
        <>
          {/* {successMessage ? (
            <Typography variant='h4'>Presentation Created!</Typography>
          ) : (
            <></>
          )} */}
          {activeStep === 1 ? (
            <Box sx={{ ...styles.stepperBox }}>
              <div style={{ margin: '10px' }}>
                <Typography variant='h6' align='left'>
                  Step 2: Select or create a presentation
                </Typography>
                <Typography align='left' variant='subtitle2'>
                  Here you can choose whether to add slides to an existing
                  presentation or to create a new presentation.
                </Typography>
                <ToggleButtonGroup
                  value={alignment}
                  exclusive
                  onChange={handleAlignmentChange}
                >
                  <ToggleButton value='existing'>Select Existing</ToggleButton>
                  <ToggleButton value='new'>Create New</ToggleButton>
                </ToggleButtonGroup>
              </div>
              {alignment === 'existing' ? (
                <div style={{ width: '90%', margin: '0 auto' }}>
                  <Typography align='left' variant='subtitle2'>
                    Select an existing presentation
                  </Typography>
                  <FormControl style={{ width: '100%' }}>
                    <InputLabel id='select-by-title'>
                      Select Presentation
                    </InputLabel>
                    <Select
                      label='Select Presentation'
                      className='dropDown'
                      labelId='select-by-title'
                      value={selectedPresentation}
                      onChange={handleSelectPresentation}
                    >
                      {presentationList.map((item) => (
                        <MenuItem value={item}> {item.title} </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                </div>
              ) : (
                <div style={{ width: '90%', margin: '0 auto' }}>
                  <Typography align='left' variant='subtitle2'>
                    Type a new presentation title
                  </Typography>
                  <TextField
                    name='report'
                    className='defaultBox'
                    value={presentationTitle}
                    placeholder='Add Title'
                    onChange={(e) => handleNewTitle(e)}
                    fullWidth
                  />
                </div>
              )}

              <div style={{ width: '90%', margin: '0 auto' }}>
                <br></br>
                  <Typography align='left' variant='subtitle2'>
                    Select a notebook type
                  </Typography>
                  <FormControl style={{ width: '100%' }}>
                    <InputLabel id='select-by-template-title'>
                      Select Template
                    </InputLabel>
                    <Select
                      key={selectedTemplate}
                      label='Select Template'
                      className='dropDown'
                      labelId='select-by-template-title'
                      value={selectedTemplate}
                      onChange={handleSelectTemplate}
                    >
                      {templateList.map((item, i) => (
                        <MenuItem value={item} key={i}> {item.label} </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
              </div>

              <div
                style={{
                  margin: '5px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  width: '94%'
                }}
              >
                <Button
                  variant='contained'
                  disabled={
                    ( selectedPresentation === '' && presentationTitle === null ) || selectedTemplate === ''
                  }
                  onClick={handleDisplayPresentations}
                >
                  {alignment === 'existing' ? 'Select' : 'Create'}
                </Button>
              </div>
            </Box>
          ) : (
            <></>
          )}

          {activeStep === 2 ? (
            <>
              {isLoading ? (
                <h1>Loading</h1>
              ) : (
                <div>
                  <div>
                    <Grid>
                      <Grid sx={{ display: 'flex', justifyContent: 'center' }} xs={12}>
                        <Tabs
                          value={currTab}
                          onChange={handleTabChange}
                          sx={mobile ? styles.mobileTabs : null}
                        >
                        <Tab label='Posts' />
                        <Tab label='Reports' />
                      </Tabs>
                      </Grid>
                    </Grid>

                    {mobile ? (
                      <Dialog open={reportDialog} onClose={closeDialog}>
                        <DialogTitle
                          style={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                          <CloseIcon onClick={closeDialog} />
                        </DialogTitle>
                        <DialogContent>{ReportDetail()}</DialogContent>
                      </Dialog>
                    ) : (
                      <Grid item xs={8}>
                        {/* {ReportDetail()} */}
                      </Grid>
                    )}
                  </div>
                  {currTab == 0? (
                  <>
                    <div>
                      {<ViewPosts 
                        handleDataChange={handleDataChange}
                        handlePostsSubmit={handlePostsSubmit}
                        selectedPresentation={selectedPresentation}
                        selectedTemplate={selectedTemplate}
                        newPresLoading={newPresLoading}
                      />}
                    </div>
                  </>
                  ) : (
                  <> </>
                  )}
                  {currTab == 1 ? (
                  <> 
                    <div>
                      <Grid
                        container
                        spacing={1}
                        sx={{ width: '80%', margin: 'auto auto' }}
                      >
                        <Grid item xs={12} sm={4}>
                          <Box
                            style={{
                              overflowY: 'scroll',
                              padding: '10px 10px',
                              maxHeight: '100vh',
                              maxWidth: '100%'
                            }}
                          >
                            {/* <List> */}
                            {allReports.map((report) => {
                              let imageUrl = null
                              if (report.figures) {
                                let tmpObj = report.figures[0]
                                if (tmpObj) {
                                  imageUrl = tmpObj['image-url']
                                }
                              }

                              let checkedTest = false
                              if (addedReports.has(report.id)) {
                                checkedTest = true
                              }
                              return (
                                // <ListItem sx={{width: '100px'}}>
                                <Badge
                                  invisible={!checkedTest}
                                  color='primary'
                                  variant='dot'
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left'
                                  }}
                                >
                                  <Card
                                    style={{
                                      width: '100%',
                                      maxWidth: '250px',
                                      height: '200px',
                                      overflow: 'hidden',
                                      cursor: 'pointer',
                                      marginBottom: '10px'
                                    }}
                                    onClick={() => selectReport(report)}
                                  >
                                    <CardHeader
                                      title={report.title}
                                      action={report.date}
                                      style={{ borderBottom: '1px solid gray' }}
                                    />

                                    <CardContent sx={{ display: 'flex' }}>
                                      {imageUrl ? (
                                        <CardMedia
                                          component='img'
                                          sx={{ width: '150px', height: '100px' }}
                                          image={imageUrl}
                                        />
                                      ) : (
                                        <CardMedia
                                          component='img'
                                          sx={{ width: '150px', height: '100px' }}
                                          image='http://via.placeholder.com/150x100'
                                        />
                                      )}
                                      <div
                                        style={{
                                          marginLeft: '10px',
                                          textOverflow: 'ellipsis',
                                          height: '75px',
                                          overflow: 'hidden'
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            marginTop: '-5px'
                                          }}
                                          variant='subtitle1'
                                        >
                                          {report.essay}
                                        </Typography>
                                      </div>
                                    </CardContent>
                                  </Card>
                                </Badge>
                                // </ListItem>
                              )
                            })}
                            {/* </List> */}
                          </Box>
                        </Grid>
                        {mobile ? (
                          <Dialog open={reportDialog} onClose={closeDialog}>
                            <DialogTitle
                              style={{ display: 'flex', justifyContent: 'flex-end' }}
                            >
                              <CloseIcon onClick={closeDialog} />
                            </DialogTitle>
                            <DialogContent>{ReportDetail()}</DialogContent>
                          </Dialog>
                        ) : (
                          <Grid item xs={8}>
                            {ReportDetail()}
                          </Grid>
                        )}
                        <Dialog open={editDialog} onClose={closeEditDialog} fullWidth>
                          <DialogTitle
                            style={{ display: 'flex', justifyContent: 'flex-end' }}
                          >
                            <CloseIcon onClick={closeEditDialog} />
                          </DialogTitle>
                          <DialogContent>
                            <EditReportView
                              id={selectedReport?.id}
                              close={closeEditDialog}
                              trigger={trigger}
                              setTrigger={setTrigger}
                            />
                          </DialogContent>
                        </Dialog>
                        <Snackbar
                          sx={{ zIndex: 0 }}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                          }}
                          open={addedReports.size > 0}
                          message={
                            isLoading ? (
                              'Generating Presentation...'
                            ) : (
                              <SnackbarMessage />
                            )
                          }
                        />
                      </Grid>
                    </div>
                  </>
                  ) : (
                    <> </>
                  )}
                </div>
              )}
            </>
          ) : (
            <></>
          )}

          {activeStep === 3 ? <>
            <Box sx={{ ...styles.stepperBox }}>
              <Typography variant='h6'>Presentation Created!</Typography>
              <Button onClick={() => setActiveStep(2)}>Add more slides</Button>
              <br></br>
              <Button onClick={exitRoute}>Done</Button>
            </Box>
          </> : <></>}
        </>
      )}
    </div>
    // <>
    //   <Container>
    //     <div style={{ marginTop: '55px' }}>
    //       <Alert severity='info'>
    //         {' '}
    //         Important! Sign in with the same Edorsum email
    //         <br></br>
    //         <br></br>
    //         {apisLoaded ? (
    //           <>
    //             <Button onClick={() => handleSignIn()}>
    //               Sign In with Google
    //             </Button>
    //           </>
    //         ) : (
    //           <> </>
    //         )}
    //       </Alert>
    //       <br></br>

    //       {userExists ? (
    //         <>
    //           {/* <div className='googleUser'>
    //             <img className="googleUserPicture" src={googleUser.picture}></img>
    //             <div className='googleUserInfo'>
    //               <div>Signed in as {googleUser.name}</div>
    //               <div>{googleUser.email}</div>
    //             </div>
    //           </div> */}

    //           <div>
    //             <Button onClick={() => handleGoogleSignOut()}>Sign Out</Button>
    //             <Button onClick={() => handleSetButton()}>
    //               {' '}
    //               Set Presentation{' '}
    //             </Button>
    //             <Button onClick={() => handleClearButton()}>
    //               {' '}
    //               Remove Presentation{' '}
    //             </Button>
    //           </div>

    //           <div>
    //             {showSelection ? (
    //               <div>
    //                 <b> Presentation Title: </b>
    //                 {presentationSelected.title}
    //                 <br></br>
    //                 <br></br>
    //               </div>
    //             ) : (
    //               <> </>
    //             )}
    //           </div>
    //           <div>
    //             {showSelection ? (
    //               <Button
    //                 type='submit'
    //                 variant='contained'
    //                 className='submit_button'
    //                 onClick={addSelectedSlides}
    //               >
    //                 {' '}
    //                 Add Selected Slides{' '}
    //               </Button>
    //             ) : (
    //               <> </>
    //             )}
    //           </div>
    //         </>
    //       ) : (
    //         <> </>
    //       )}

    //       <div>{userExists ? <DisplayReports /> : <> </>}</div>
    //       <div>
    //         <Grid md={6} lg={6}>
    //           <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    //             {isShown ? (
    //               <Dialog
    //                 open={open}
    //                 onClose={handleClose}
    //                 fullWidth
    //                 style={{ zIndex: '9999' }}
    //               >
    //                 <DialogTitle>
    //                   Edit Report
    //                   <CloseIcon
    //                     style={{ float: 'right' }}
    //                     onClick={handleClose}
    //                   />
    //                 </DialogTitle>
    //                 <DialogContent>
    //                   <div>
    //                     <EditReportView id={ID} />
    //                   </div>
    //                 </DialogContent>
    //               </Dialog>
    //             ) : (
    //               <> </>
    //             )}
    //           </div>

    //           <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    //             {showInfo ? (
    //               <Dialog open={openOther} onClose={handleClose} fullWidth>
    //                 <DialogTitle>
    //                   Set a Presentation
    //                   <CloseIcon
    //                     style={{ float: 'right' }}
    //                     onClick={handleClose}
    //                   />
    //                 </DialogTitle>
    //                 <DialogContent>
    //                   <div>{PresentationSelection()}</div>
    //                 </DialogContent>
    //               </Dialog>
    //             ) : (
    //               <> </>
    //             )}
    //           </div>
    //         </Grid>
    //       </div>
    //     </div>
    //     <ScrollToTop smooth style={{ borderRadius: '50%' }} />
    //   </Container>
    // </>
  )
}

export default UserAIReports