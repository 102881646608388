import { useState } from 'react'
import {
  Chip,
  Tooltip,
  Menu,
  MenuItem,
  createTheme,
  ThemeProvider
} from '@mui/material'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import PeopleIcon from '@mui/icons-material/People'
import '../Styling/Project.css'

const FriendIcon = ({
  relation,
  handleRemove,
  handleRequest,
  handleAccept,
  handleReject
}) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#1B89D6'
      },
      secondary: {
        main: '#cecece'
      }
    }
  })

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleRespond = async (response) => {
    if (response === 'accept') {
      handleAccept()
    } else {
      handleReject()
    }
    handleClose()
  }

  const CHIPS = {
    friend: {
      icon: <PersonRemoveIcon fontSize='small' />,
      message: 'Remove Friend',
      handleClick: handleRemove,
      color: 'secondary',
      clickable: true,
      variant: 'outlined'
    },
    none: {
      icon: <PersonAddIcon fontSize='small' />,
      message: 'Request Friend',
      handleClick: handleRequest,
      color: 'primary',
      clickable: true,
      variant: 'filled'
    },
    sentRequest: {
      icon: <AccessTimeIcon fontSize='small' />,
      message: 'Request Pending',
      handleClick: null,
      color: 'secondary',
      clickable: false,
      variant: 'filled'
    },
    receivedRequest: {
      icon: <PeopleIcon fontSize='small' />,
      message: 'Respond to Friend Request',
      handleClick: handleClick,
      clickable: true,
      color: 'primary',
      variant: 'outlined'
    }
  }

  return (
    <>
      <div className='friend_button' onClick={CHIPS[relation]?.handleClick}>
        <ThemeProvider theme={theme}>
          <Tooltip title={CHIPS[relation]?.message} className='friend_button'>
            <Chip
              icon={CHIPS[relation]?.icon}
              color={CHIPS[relation]?.color}
              size='small'
              clickable={CHIPS[relation]?.clickable}
              aria-controls={open ? 'friend-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              variant={CHIPS[relation]?.variant}
            />
          </Tooltip>
        </ThemeProvider>
      </div>
      <Menu
        anchorEl={anchorEl}
        id='friend-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem onClick={() => handleRespond('accept')}>Accept</MenuItem>
        <MenuItem onClick={() => handleRespond('reject')}>Reject</MenuItem>
      </Menu>
    </>
  )
}

export default FriendIcon
