import { useEffect, useState } from 'react'
import {
  Button,
  Typography,
  Toolbar,
  Menu,
  MenuItem,
  useMediaQuery,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  ListItem
} from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import '../Styling/Navi.css'
import { useNavigate } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
// import EditIcon from '@mui/icons-material/Edit'
// import FileCopyIcon from '@mui/icons-material/FileCopy'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
// import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import { HomeRounded, Close, Create } from '@mui/icons-material/'
import { auth, logOut, db } from '../Firebase/FirebaseConfig'
import { doc, getDoc } from 'firebase/firestore'

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}))

const Navi = () => {
  const navigate = useNavigate()
  const [user, loading, error] = useAuthState(auth)
  const [role, setRole] = useState()

  const handleNavBarChange = async () => {
    let userRoleTemp = 2;
    const userRef = doc(db, 'users', user.uid)
    const userSnapshot = await getDoc(userRef)
    try{
      if(userSnapshot.data().hasOwnProperty('role')){
        userRoleTemp  =  userSnapshot.data()['role']
      }
      setRole(userRoleTemp)
    }catch(err){
      console.error(err)
    }
  }

  useEffect(() => {
    if (loading) return
    if(!user){
      return
    }else{
      handleNavBarChange()
    }
  }, [user])

  // const generateEssay = () => {
  //   console.log('generate essay')
  //   let path = '/SelectPosts'
  //   navigate(path)
  // }

  const [createAnchor, setCreateAnchor] = useState(null)
  const openCreate = Boolean(createAnchor)
  const handleCreateClick = (event) => {
    setCreateAnchor(event.currentTarget)
  }
  const handleCloseCreate = () => {
    setCreateAnchor(null)
  }

  const [profileAnchor, setProfileAnchor] = useState(null)
  const openProfile = Boolean(profileAnchor)
  const handleProfileClick = (event) => {
    setProfileAnchor(event.currentTarget)
  }
  const handleCloseProfile = () => {
    setProfileAnchor(null)
  }

  const mobile = useMediaQuery('(max-width:600px)')
  const BUTTONS = [
    {
      label: 'Home',
      icon: <HomeRounded />,
      handleClick: () => navigate('/'),
      lowestAccessLvl: 1
    },
    {
      label: 'Create',
      icon: <Create />,
      handleClick: handleCreateClick,
      lowestAccessLvl: 2
    },
    {
      label: 'Account',
      icon: <AccountCircleRoundedIcon />,
      handleClick: handleProfileClick,
      lowestAccessLvl: 0
    }
  ]

  const MENUCONTENT = {
    create: [
      {
        label: 'New Report',
        handleClick: () => {
          navigate('/SelectPosts')
          handleCloseCreate()
        }
      },
      {
        label: 'New Presentation',
        handleClick: () => {
          //navigate('/ViewAllReports')
          navigate('/ViewAllReports', {
            state: {
              prevStep: 0,
              fromViewReport: false,
              prevPresentation: '',
              prevTemplate: ''
            }
          })
          handleCloseCreate()
        }
      }
    ],
    profile: [
      {
        label: 'Profile',
        handleClick: () => {
          navigate('/UserProfile', { state: { prevTab: 0 } })
          handleCloseProfile()
        }
      },
      // TO DO: Build Account Settings page
      // { label: 'Account Settings' },
      {
        label: 'Log Out',
        handleClick: () => {
          navigate('/')
          logOut()
          handleCloseProfile()
        }
      }
    ]
  }

  return (
    <Toolbar className='nav_container'>
      {mobile ? (
        <>
          {user ? (
            <>
              {BUTTONS.filter(button => button.lowestAccessLvl <= role).map((filteredButton) => {
                return (
                  <Button
                    className='mobile_button'
                    onClick={filteredButton.handleClick}
                  >
                    {filteredButton.icon}
                    <Typography variant='caption'>{filteredButton.label}</Typography>
                  </Button>
                )
              })}
              <Drawer
                anchor={'bottom'}
                open={openCreate}
                onClose={handleCloseCreate}
              >
                <List>
                  <ListItem>
                    <IconButton onClick={handleCloseCreate}>
                      <Close />
                    </IconButton>
                    Create
                  </ListItem>
                  {MENUCONTENT.create.map((item) => {
                    return (
                      <ListItemButton onClick={item.handleClick}>
                        <ListItemText primary={item.label} />
                      </ListItemButton>
                    )
                  })}
                </List>
              </Drawer>
              <Drawer
                anchor={'bottom'}
                open={openProfile}
                onClose={handleCloseProfile}
              >
                <List>
                  <ListItem>
                    <IconButton onClick={handleCloseProfile}>
                      <Close />
                    </IconButton>
                    Profile
                  </ListItem>
                  {MENUCONTENT.profile.map((item) => {
                    return (
                      <ListItemButton onClick={item.handleClick}>
                        <ListItemText primary={item.label} />
                      </ListItemButton>
                    )
                  })}
                </List>
              </Drawer>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <Button>
            <h2 className='navi_site_name'>EDORSUM</h2>
          </Button>
          {user ? (
            <div className='nav_container'>
              <div className='nav_options'>
                {role >= 1 ? (
                <Button onClick={() => navigate('/')}>
                  <Typography variant='h6' className='nav_item'>
                    Home
                  </Typography>
                </Button>
                ) : (<></>)}

                {role >= 2 ? (
                <>
                <Button
                  onClick={handleCreateClick}
                  endIcon={<KeyboardArrowDownIcon className='down_arrow' />}
                  className='nav-item'
                >
                  <Typography variant='h6' className='nav_item'>
                    Create
                  </Typography>
                </Button>
                <StyledMenu
                  id='customized-menu'
                  anchorEl={createAnchor}
                  open={openCreate}
                  onClose={handleCloseCreate}
                >
                  {MENUCONTENT.create.map((item) => {
                    return (
                      <MenuItem onClick={item.handleClick} disableRipple>
                        {item.label}
                      </MenuItem>
                    )
                  })}
                </StyledMenu>
                </> ) : (<></>)}
              </div>

              <Button
                endIcon={<KeyboardArrowDownIcon className='down_arrow' />}
                onClick={handleProfileClick}
              >
                <AccountCircleRoundedIcon className='profile_icon' />
              </Button>

              <StyledMenu
                id='customized-menu'
                anchorEl={profileAnchor}
                open={openProfile}
                onClose={handleCloseProfile}
              >
                {MENUCONTENT.profile.map((item) => {
                  return (
                    <MenuItem onClick={item.handleClick} disableRipple>
                      {item.label}
                    </MenuItem>
                  )
                })}
              </StyledMenu>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </Toolbar>
  )
}
export default Navi
