//Updates existing posts in Firebase
import React, { useState, useEffect } from "react";
import { db, auth } from '../Firebase/FirebaseConfig';
import { doc, collection, updateDoc, serverTimestamp } from "firebase/firestore";

const UpdatePost = async (title, posts, docId) => {
	const user = auth.currentUser;
	const userRef = doc(db, 'users', user.uid)
	const projectsRef = collection(userRef, 'projects')
	const docRef = doc(projectsRef, docId)

	try {
		await updateDoc(docRef, {
			title: title,
			posts: posts,
			'date-updated': serverTimestamp()
		});
		return(true)

	} catch (err) {
		console.log("error:", err)
	}
};
export default UpdatePost;