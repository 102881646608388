import React, { useState, useEffect}  from "react";
import { v4, v1 } from 'uuid'
import { gapi } from "gapi-script";

//____________________________________________________________________________________________________________________________________

//Creates a presentation using AppsScript API
async function createPresentation(title, template) {
  const deploymentID = process.env.REACT_APP_APPS_SCRIPT_DEPLOYMENT_ID;
  //const deploymentID = 'AKfycbwiSYRunU8cVGeg0BsG6pd4EkIke_czgWAeuQl-VWGUoCageG0GaKl3_NDxXrx9l2y3MQ';

  const templateID = template.copyId;
  let AppScriptFunction;
  
  //checks for template selected
  return new Promise((resolve) => {
    console.log("template.type:", template.type)
    if(template.type == 'VRC'){
      console.log("type VRC")
      AppScriptFunction = 'createVRCPresentationTemp';
    }else if(template.type == 'VIQC'){
      console.log("type VIQC")
      AppScriptFunction = 'createVIQCPresentation';
    }else{
      console.log("type ELSE")
      AppScriptFunction = 'createBlankPresentation';
    }

    try {
      gapi.client.script.scripts.run({
        'scriptId': deploymentID,
        'resource': {
          'function': AppScriptFunction,
          'parameters':[
            templateID,
            title
          ],
        },
      }).then(function(resp) {
        const result = resp.result;
        if (result.error && result.error.status) {
          // The API encountered a problem before the script
          console.log('Error calling API:')
          console.log(result, null, 2)

        } else if (result.error) {
          // The API executed, but the script returned an error. Extract the first (and only) set of error details.
          // The values of this object are the script's 'errorMessage' and 'errorType', and an array of stack trace elements.
          const error = result.error.details[0];
          console.log('Script error message: ' + error.errorMessage);

          if (error.scriptStackTraceElements) {
            // There may not be a stacktrace if the script didn't start executing.
            console.log('Script error stacktrace:');
            for (let i = 0; i < error.scriptStackTraceElements.length; i++) {
              const trace = error.scriptStackTraceElements[i];
              console.log('\t' + trace.function + ':' + trace.lineNumber);
            }
          }
        } else {
          // The structure of the result will depend upon what the Apps Script function returns. Here, the function returns an Apps
          // Script Object with String keys and values, and so the result is treated as a JavaScript object (folderSet).
          //console.log("result.response.result", result.response.result)
          resolve(result.response.result);
        }
      })
   
    }catch(err){
      console.log("err:", err)
    }
  })
}


//____________________________________________________________________________________________________________________________________

//Two functions: Adds multiple template slides to a presentatio and then updates all plaaceholders
async function addMultipleSlides(addedReports, presID, template){
  let reportTempList;
  let AppScriptFunction;

  if(template.type == 'VRC'){
    AppScriptFunction = 'addMultipleVRCSlides';
  }else if(template.type == 'VIQC'){
    AppScriptFunction = 'addMultipleVIQCSlides';
  }else{
    AppScriptFunction = 'addMultipleSlides';
  }

  //copy over template slides
  await runScriptAddTemplates(addedReports, presID, AppScriptFunction, template).then((response) => {
    reportTempList = response;
  })

  //replace placeholders with content
  await replaceMultipleSlides(presID, reportTempList);
}

//#1. Add all the templates needed
async function runScriptAddTemplates(addedReports, presID, AppScriptFunction, template) {// console.log("runScriptAddTemplates")
  const templateID = template.presentationId;
  const array = Array.from(addedReports, ([key, value]) => ({key,value}))

  return new Promise((resolve) => {
    const deploymentID = process.env.REACT_APP_APPS_SCRIPT_DEPLOYMENT_ID;
    //const deploymentID = 'AKfycbwiSYRunU8cVGeg0BsG6pd4EkIke_czgWAeuQl-VWGUoCageG0GaKl3_NDxXrx9l2y3MQ'
    
    try {
      gapi.client.script.scripts.run({
        'scriptId': deploymentID,
        'resource': {
          'function': AppScriptFunction,
          'parameters':[
            templateID,
            presID,
            array
          ],
        },
      }).then(function(resp) {
        const result = resp.result;

        if (result.error && result.error.status) {
          console.log('Error calling API:')
          console.log(result, null, 2)

        } else if (result.error) {
          const error = result.error.details[0];
          console.log('Script error message: ' + error.errorMessage);

          if (error.scriptStackTraceElements) {
            console.log('Script error stacktrace:');
            for (let i = 0; i < error.scriptStackTraceElements.length; i++) {
              const trace = error.scriptStackTraceElements[i];
              console.log('\t' + trace.function + ':' + trace.lineNumber);
            }
          }

        } else {
          const reportTempList = result.response.result
          resolve(reportTempList)
        }
      });
    
    } catch (err) {
      console.log("error:", err);
      return;
    }
  })

  
}

//#2. replaces all the placeholders in the template slides with the actual report values
async function replaceMultipleSlides(presID, reportTempList){
  let textRequest;
  let eachImageRequest;
  const imageRequests = [];
  const allTextRequests = [];
  const thisMap = new Map(reportTempList.map((obj) => [obj.key, obj.value]))

  return new Promise((resolve) => {
    try{
      thisMap.forEach(function(value, key){

        //handle text
        textRequest = [
          {
            replaceAllText: {
              "replaceText" : value.essay,
              "pageObjectIds" : value.slideId,
              "containsText" : {
                "text" : "{{page_body}}",
                "matchCase" : false,
              } 
            }
          },
          {
            replaceAllText: {
              "replaceText" : value.title,
              "pageObjectIds" : value.slideId,
              "containsText" : {
                "text" : "{{page_title}}",
                "matchCase" : false,
              } 
            }
          },
          {
            replaceAllText: {
              "replaceText" : value.date,
              "pageObjectIds" : value.slideId,
              "containsText" : {
                "text" : "{{date}}",
                "matchCase" : false,
              } 
            }
          },
        ];
        allTextRequests.push(textRequest);

        //handle images
        value.figures.map((fig, index) => {          
          eachImageRequest = [
            {
              replaceAllShapesWithImage: {
                "imageReplaceMethod": "CENTER_INSIDE",
                "pageObjectIds": [value.slideId],
                "containsText": {
                  "text" : "{{img_banner_" + index + "}}",
                  "matchCase": false,
                },
                "imageUrl": fig['image-url'],
              }
            },
            {
              replaceAllText: {
                "replaceText" : fig.caption,
                "pageObjectIds" : value.slideId,
                "containsText" : {
                  "text" : "{{img_caption_" + index + "}}",
                  "matchCase" : false,
                } 
              }
            }
          ]
          imageRequests.push(eachImageRequest)
        })
      })
    }catch(err){
      console.log("err:", err)
    }

    try{
      gapi.client.slides.presentations.batchUpdate({
        presentationId: presID,
        requests: [allTextRequests, imageRequests]
      })
      .then((createSlideResponse) => {
        resolve(createSlideResponse)
      })
      resolve();
    }catch(err){
      console.log("createSlide err:", err)
    }

  })
}

//____________________________________________________________________________________________________________________________________
async function runScriptAddPostTemplates(posts, presID, AppScriptFunction, template) {// console.log("runScriptAddTemplates")
  //console.log("runScriptAddPostTemplates:")
  const templateID = template.presentationId;  
  const arrayResult = Object.keys(posts).map((key) => posts[key])

  return new Promise((resolve) => {
    const deploymentID = process.env.REACT_APP_APPS_SCRIPT_DEPLOYMENT_ID;    
    try {
      gapi.client.script.scripts.run({
        'scriptId': deploymentID,
        'resource': {
          'function': AppScriptFunction,
          'parameters':[
            templateID,
            presID,
            arrayResult
          ],
        },
      }).then(function(resp) {
        const result = resp.result;

        if (result.error && result.error.status) {
          console.log('Error calling API:')
          console.log(result, null, 2)

        } else if (result.error) {
          const error = result.error.details[0];
          console.log('Script error message: ' + error.errorMessage);

          if (error.scriptStackTraceElements) {
            console.log('Script error stacktrace:');
            for (let i = 0; i < error.scriptStackTraceElements.length; i++) {
              const trace = error.scriptStackTraceElements[i];
              console.log('\t' + trace.function + ':' + trace.lineNumber);
            }
          }
        } else {
          const postsSlideslist = result.response.result
          console.log("postsSlideslist:", postsSlideslist)
          resolve(postsSlideslist)
        }
      });
    } catch (err) {
      console.log("error:", err);
      return;
    }
  })
}


async function replaceMultiplePostSlides(presID, newPostsLists){
  //console.log("replaceMultiplePostSlides:", )
  let AllRequests;
  const allPostRequests = [];
  
  return new Promise((resolve) => {
    try{
      newPostsLists.map((item,index) => {

        AllRequests = [
          {
            replaceAllText: {
              "replaceText" : item.caption,
              "pageObjectIds" : item.slideId,
              "containsText" : {
                "text" : "{{page_body}}",
                "matchCase" : false,
              } 
            }
          },
          {
            replaceAllText: {
              "replaceText" : item.date,
              "pageObjectIds" : item.slideId,
              "containsText" : {
                "text" : "{{date}}",
                "matchCase" : false,
              } 
            }
          },
          {
            replaceAllText: {
              "replaceText" : item.project,
              "pageObjectIds" : item.slideId,
              "containsText" : {
                "text" : "{{page_title}}",
                "matchCase" : false,
              } 
            }
          },
          {
            replaceAllShapesWithImage: {
              "imageReplaceMethod": "CENTER_INSIDE",
              "pageObjectIds": [item.slideId],
              "containsText": {
                "text" : "{{img_banner_0}}",
                "matchCase": false,
              },
              "imageUrl": item['image-url'],
            }
          },
        ]
        allPostRequests.push(AllRequests);
      })

    }catch(err){
      console.log("err:", err)
    }

    try{
      gapi.client.slides.presentations.batchUpdate({
        presentationId: presID,
        requests: [allPostRequests]
      })
      .then((createSlideResponse) => {
        resolve(createSlideResponse)
      })
      resolve();
    }catch(err){
      console.log("createSlide err:", err)
    }

  })
}

async function addMultiplePostSlides(value, presID, template){
  //console.log("ADD MULTIPLE POSTS SLIDES")
  let newPostsLists;
  let AppScriptFunction;

  if(template.type == 'VRC'){
    AppScriptFunction = 'addMultiplePostsVRCSlides';
  }else if(template.type == 'VIQC'){
    AppScriptFunction = 'addMultiplePostsVIQCSlides';
  }else{
    AppScriptFunction = 'addMultipleSlides';
  }

  //copy over template slides
  await runScriptAddPostTemplates(value, presID, AppScriptFunction, template).then((response) => {
    newPostsLists = response;
  })

  //replace placeholders with content
  await replaceMultiplePostSlides(presID, newPostsLists);

  return;
}

//____________________________________________________________________________________________________________________________________

export{
  createPresentation, addMultipleSlides, addMultiplePostSlides
}