import { useState, useEffect } from 'react'
import { db, auth } from '../Firebase/FirebaseConfig'
import {
  collection,
  query,
  getDocs,
  where,
  doc,
  updateDoc,
  setDoc,
  deleteField,
  deleteDoc,
  getDoc,
  addDoc,
  Timestamp
} from 'firebase/firestore'
import { useAuthState } from 'react-firebase-hooks/auth'
import {
  Typography,
  Checkbox,
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  IconButton,
  Avatar,
  Divider,
  TextField,
  InputAdornment,
  Tooltip
} from '@mui/material'
import Comment from './Comment'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import CheckIcon from '@mui/icons-material/Check'
import SendIcon from '@mui/icons-material/Send'
import '../Styling/Feedback.css'

const ProjectFeedback = ({ projectID }) => {
  const [checked, setChecked] = useState(false)
  const [user, loading, error] = useAuthState(auth)
  const [userProfile, setUserProfile] = useState(null)
  const [likeUsers, setLikeUsers] = useState(new Map())
  const [comments, setComments] = useState([])
  const [comment, setComment] = useState('')
  const [showComment, setShowComment] = useState(false)
  const [showComments, setShowComments] = useState(false)

  const getLikes = async () => {
    try {
      let tmpArray = []
      const q = query(
        collection(db, 'likes'),
        where('__name__', '==', projectID)
      )
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        let userLikes = Object.keys(doc.data())
        tmpArray.push(...userLikes)
      })
      if (tmpArray.includes(user.uid)) {
        setChecked(true)
      }
      return tmpArray
    } catch (err) {
      console.log(err)
    }
  }

  const getUser = async (userId) => {
    try {
      const userRef = doc(db, 'users', userId)
      const userSnapshot = await getDoc(userRef)
      return userSnapshot.data()
    } catch (err) {
      console.log(err)
    }
  }

  const getProfile = async () => {
    const userRef = doc(db, 'users', user.uid)
    const userSnapshot = await getDoc(userRef)
    setUserProfile(userSnapshot.data())
  }

  const getComments = async () => {
    let tmpComments = []
    const q = query(
      collection(db, 'Comments'),
      where('projectUID', '==', projectID)
    )
    const commentSnapshot = await getDocs(q)
    commentSnapshot.forEach((doc) => {
      tmpComments.push(doc.data())
    })
    setComments(tmpComments)
  }

  useEffect(() => {
    getLikes().then((likes) => {
      likes.forEach((userId) => {
        //use user ids from Likes obj to get user data
        getUser(userId).then((user) => {
          setLikeUsers((likeUsers) => new Map(likeUsers.set(userId, user)))
        })
      })
    })
    getProfile()
    getComments()
  }, [])

  const handleLike = async () => {
    const userID = user.uid
    const likeRef = doc(db, 'likes', projectID)
    if (checked) {
      //Handle removal of like
      if (likeUsers.size === 1) {
        await deleteDoc(doc(db, 'likes', projectID))
        setLikeUsers(new Map())
      } else {
        await updateDoc(likeRef, { [userID]: deleteField() })
        setLikeUsers((likeUsers) => {
          likeUsers.delete(user.uid)
          return new Map(likeUsers)
        })
      }
    } else {
      //Handle adding like
      if (likeUsers.size > 0) {
        await updateDoc(likeRef, { [userID]: true })
      } else {
        await setDoc(doc(db, 'likes', projectID), { [userID]: true })
      }
      setLikeUsers((likeUsers) => new Map(likeUsers.set(user.uid, userProfile)))
    }
    setChecked(!checked)
  }

  const handleShowComment = () => {
    setShowComment(!showComment)
  }

  const handleChangeComment = (value) => {
    setComment(value)
  }

  const handleSubmitComment = async () => {
    let commentObj = {
      comment: comment,
      createdTime: Timestamp.fromDate(new Date()),
      isGroup: 'false',
      projectUID: projectID,
      type: 'text',
      userUID: user.uid
    }
    await addDoc(collection(db, 'Comments'), commentObj)
    setComment('')

    //add comment to front end array
    let tmpComments = [...comments]
    tmpComments.push(commentObj)
    setComments(tmpComments)

    //close comment text area
    setShowComment(false)
  }

  const handleShowComments = () => {
    setShowComments(!showComments)
  }

  const likeMessage = () => {
    let icon = <CheckCircleOutlineIcon />
    let message = ''

    if (likeUsers.size > 0) {
      if (checked) {
        icon = <CheckCircleIcon color='primary' />
        if (likeUsers.size > 1) {
          message = `You and ${likeUsers.size - 1} others`
        }
      } else {
        message = likeUsers.size
        if (likeUsers.size > 1) {
          message = message + ' people'
        } else {
          message = message + ' person'
        }
      }
    }

    return (
      <div className='like_total'>
        {icon}
        {<Typography variant='caption'>{message}</Typography>}
      </div>
    )
  }

  return (
    <div>
      <div className='feedback_container'>
        <Tooltip
          title={
            likeUsers.size > 0 ? (
              <>
                <Typography variant='caption'>Read By:</Typography>
                <br />
                {[...likeUsers.values()].map((user) => {
                  return (
                    <>
                      <Typography variant='caption'>{user.name}</Typography>
                      <br />
                    </>
                  )
                })}
              </>
            ) : (
              'No Reads Yet'
            )
          }
        >
          {likeMessage()}
        </Tooltip>
        <div className='comment_total' onClick={handleShowComments}>
          <Typography className='comment_number'>{comments.length}</Typography>
          <Tooltip title={showComments ? 'Hide Comments' : 'Show Comments'}>
            <ChatBubbleOutlineIcon fontSize='small' />
          </Tooltip>
        </div>
      </div>
      <Divider />
      <div className='interact_container'>
        <div className='interact' onClick={handleLike}>
          <CheckIcon color={checked ? 'primary' : ''} />
          <Typography color={checked ? 'primary' : ''}>
            {checked
              ? 'Marked as Read and Understood'
              : 'Click to Mark as Read and Understood'}
          </Typography>
        </div>
        <div className='interact' onClick={handleShowComment}>
          <ChatBubbleOutlineIcon />
          <Typography>Comment</Typography>
        </div>
      </div>
      {showComments ? (
        <>
          <Divider />
          {comments.length > 0 ? (
            <>
              {comments.map((comment) => {
                return (
                  <>
                    <Comment comment={comment} key={comment.uid} />
                    <Divider />
                  </>
                )
              })}
            </>
          ) : (
            <Typography>No Comments</Typography>
          )}
        </>
      ) : (
        <></>
      )}
      {showComment ? (
        <div className='comment'>
          <TextField
            multiline
            maxRows={5}
            placeholder='Write Comment'
            style={{ width: '100%', marginBottom: '10px' }}
            onChange={(e) => handleChangeComment(e.target.value)}
            value={comment}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={handleSubmitComment}>
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default ProjectFeedback
