import { initializeApp } from 'firebase/app'
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  signInWithCredential
} from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'

console.log(process.env.REACT_APP_ENV)
const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
  databaseURL: `${process.env.REACT_APP_FIREBASE_DATABASE_URL}`,
  projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
  appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
  measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
  clientId: `${process.env.REACT_APP_FIREBASE_CLIENT_ID}`,
  scopes: ['https://www.googleapis.com/auth/drive'],
  discoveryDocs: [
    'https://slides.googleapis.com/$discovery/rest?version=v1',
    'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'
  ]
}

/*
//Production config
const firebaseConfig = {
  apiKey: "AIzaSyCFrXwo-B2ow7HK2rBMObrehdkzc6vsRGU",
  authDomain: "edorsumdev.firebaseapp.com",
  databaseURL: "https://edorsumdev-default-rtdb.firebaseio.com",
  projectId: "edorsumdev",
  storageBucket: "edorsumdev.appspot.com",
  messagingSenderId: "337394037105",
  appId: "1:337394037105:web:8bd47a912fd833c40c06d0",
  measurementId: "G-EQR5CVD96Q",
  clientId: "337394037105-6br5238eodlfqis2b7tck0rdkj7fk663.apps.googleusercontent.com",
  scopes: [
    "https://www.googleapis.com/auth/drive"
  ],
  discoveryDocs:[
    "https://slides.googleapis.com/$discovery/rest?version=v1",
    "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"
  ]
};
*/

// //Develop config
// const firebaseConfig = {
//   apiKey: "AIzaSyCJqy818iv0DZRkyBpItoIpv9XGnaPMN8I",
//   authDomain: "edorsumdevdb.firebaseapp.com",
//   projectId: "edorsumdevdb",
//   storageBucket: "edorsumdevdb.appspot.com",
//   messagingSenderId: "196348945087",
//   appId: "1:196348945087:web:22555dbf8e8627974a9c0a",
//   measurementId: "G-JJDVHGCEC8",
//   clientId: "196348945087-nqhjcbcp4t8mtcpbugn99iromr5u9gls.apps.googleusercontent.com",
//   scopes: [
//     "https://www.googleapis.com/auth/drive"
//   ],
//   discoveryDocs:[
//     "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"
//   ]
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig)
//const analytics = getAnalytics(app);

//check if emulator should be used
let useEmulator = false
if (window.location.href.indexOf('localhost') > -1) {
  //useEmulator = true
}

const auth = getAuth(app)
const db = getFirestore(app)
if (useEmulator) {
  connectFirestoreEmulator(db, 'localhost', 8080)
}
const currUser = auth.currentUser
let token
let gisInited = false

//Firebase Authentication
const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
    console.log('you are signed in')
  } catch (error) {
    return null
    console.error(error)
    alert(error.message)
  }
}

//Firebase Authentication with Google Provider
const LogInWithGoogle = async (credential) => {
  return new Promise((resolve) => {
    try {
      signInWithCredential(auth, credential)
        .then((result) => {
          //console.log("auth:",auth)
          resolve()
        })
        .catch((err) => {
          const errorCode = err.code
          const errorMessage = err.message
          const email = err.customData.email
          const credential = GoogleAuthProvider.credentialFromError(err)
          console.log(
            'ERROR while Sign In With Google:',
            errorCode + errorMessage
          )
        })
    } catch (error) {
      console.log('error:', error)
    }
  })
}

const logOut = () => {
  signOut(auth)
}

export {
  auth,
  db,
  currUser,
  logInWithEmailAndPassword,
  logOut,
  LogInWithGoogle,
  firebaseConfig
}
