import { useEffect, useState } from 'react'
import { db } from '../Firebase/FirebaseConfig'
import { getDoc, doc } from 'firebase/firestore'
import { Typography, Avatar, Divider } from '@mui/material'
import '../Styling/Comment.css'

const Comment = ({ comment }) => {
  const [user, setUser] = useState(null)
  const [profilePic, setProfilePic] = useState('')
  const commentDate = comment?.createdTime.toDate().toLocaleDateString() || ''

  useEffect(() => {
    async function handleAsync() {
      //Get user
      const userRef = doc(db, 'users', comment.userUID)
      const userSnap = await getDoc(userRef)
      //set user data
      setUser(userSnap.data())
      //set profile picture
      if (userSnap.data()['profile-image-url']) {
        const image = userSnap.data()['profile-image-url']
        setProfilePic(image)
      }
    }
    handleAsync()
  }, [])

  return (
    <div className='comment'>
      <Avatar src={profilePic} className='avatar' />
      <div className='text_container'>
        <div className='header'>
          <Typography className='username'>{user?.name}</Typography>
          <Typography className='date'>{commentDate}</Typography>
        </div>
        <Typography align='left'>{comment.comment}</Typography>
      </div>
    </div>
  )
}

export default Comment
