import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import {
  auth,
  logInWithEmailAndPassword,
  LogInWithGoogle,
  firebaseConfig,
  db
} from '../Firebase/FirebaseConfig'
import '../Styling/Login.css'
import {
  TextField,
  Grid,
  Box,
  Divider,
  InputAdornment,
  IconButton,
  Button,
  Alert,
  Snackbar,
  Typography
} from '@mui/material'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { GoogleAuthProvider } from 'firebase/auth'
import {  doc,  getDoc } from 'firebase/firestore'
import AppStoreLogo from "../images/AppStoreLogo.svg"

const LogInUser = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [user, loading, error] = useAuthState(auth)
  const [wrongPass, setWrongPass] = useState(false)
  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)
  const [credentials, setCredentials] = useState({})

  const footerButtons = [
    { text: 'Terms & Conditions', sectionId: 'terms-and-conditions' },
    { text: 'Privacy Policy', sectionId: 'privacy-policy'},
  ]
  
  const navigateToTerms = (sectionId) => {
    const newWindow = window.open("/terms")
    setTimeout(() => {
      const section = newWindow.document.getElementById(sectionId);
      if(section){
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  }

  const getRole = async () => {
    let userRole = 2;
    const userRef = doc(db, 'users', user.uid)
    const userSnapshot = await getDoc(userRef)
    try {
      if(userSnapshot.data().hasOwnProperty('role')){
        userRole = userSnapshot.data()['role']
      }
      return(userRole)      
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (!loading && user) {
      //navigate('/')
      setWrongPass(false)
      getRole().then((resp) => {
        if(resp === 0){
          navigate('/UserProfile', { state: { prevTab: 0 } })
        }else{
          navigate('/')
        }
      })
    }
  }, [user, loading])

  const onFormSubmit = (e) => {
    e.preventDefault()
    // send state to server with e.g. `window.fetch`
  }

  const handleGoogleSignIn = (cred) => {
    LogInWithGoogle(cred).then((auth) => {
      navigate('/UserProfile', { state: { prevTab: 0 } })
    })
  }

  const handleCredentialResponse = (response) => {
    if (!response.client_id || !response.credential) {
      return
    } else {
      const token = response.credential
      const cred = GoogleAuthProvider.credential(token)
      setCredentials(cred)
      handleGoogleSignIn(cred)
    }
  }

  useEffect(() => {
    /*global google*/

    google.accounts.id.initialize({
      client_id: firebaseConfig.clientId,
      auto_select: 'false',
      callback: handleCredentialResponse
    })

    google.accounts.id.renderButton(document.getElementById('loginButton'), {
      type: 'standard',
      shape: 'rectangular',
      theme: 'filled_blue',
      text: 'signin',
      size: 'large',
      logo_alignment: 'left'
    })
  }, [])

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={wrongPass}
        handleClose={() => setWrongPass(false)}
        TransitionProps={{ onExited: () => setWrongPass(false) }}
      >
        <Alert severity='error'>
          Wrong account details or password. <br />
          Forgot your password? Please use the mobile app to reset it
        </Alert>
      </Snackbar>
      <Box
        sx={{
          maxHeight: { sm: '95vh' },
          minHeight: { xs: '100vh', sm: '500px' },
          width: { sm: '80%', md: '70%' },
          maxWidth: '900px',
          margin: { sm: '30px auto' }
        }}
        className='login_container'
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7}>
            {/* <Box
              component='img'
              src={require('../images/whale.jpeg')}
              alt='whale'
              className='login_image'
            /> */}
            <img
              src={require('../images/fin.png')}
              alt='fin'
              className='login_image'
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <img
              src={require('../images/robie-circle.png')}
              className='robie_pic'
            />
            <h1 className='site_name'>EDORSUM</h1>
            <form onSubmit={onFormSubmit}>
              <TextField
                id='email-field'
                label='Email'
                variant='outlined'
                className='login_textBox'
                type='email'
                value={email}
                onChange={(element) => setEmail(element.target.value)}
              />

              <br></br>
              <br></br>

              <TextField
                id='password-field'
                label='Password'
                variant='outlined'
                className='login_textBox'
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(elem) => setPassword(elem.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />

              <br></br>

              {/* These buttons don't work yet */}
              {/* <p className='forgot_psswd'>Forgot Password?</p>
                    <p className='create_account'>Create an Account</p> */}

              <br></br>
              <Button
                type='submit'
                variant='contained'
                className='submit_button'
                onClick={() => {
                  setWrongPass(false)
                  logInWithEmailAndPassword(email, password).then((user) => {
                    if (user) {
                      setWrongPass(false)
                      navigate('/UserProfile', { state: { prevTab: 0 } })
                    } else {
                      setWrongPass(true)
                    }
                  })
                }}
              >
                {' '}
                Login{' '}
              </Button>
            </form>

            <Divider sx={{ padding: '15px 0px' }}>OR</Divider>

            <div className='gooLoginButton'>
              <div id='loginButton'></div>
            </div>
            <br></br>
            <br></br>

            <Button
            onClick={() => window.open("https://apps.apple.com/us/app/edorsum/id1641176832", "_blank") }>
              <img
                width={"200vw"}
                src={AppStoreLogo} alt="AppStoreLogo"
              /> 
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ padding: '25px 0px' }}></Divider>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          gridTemplateRows: 'auto auto',
          paddingTop: '10px',
          paddingLeft: '40px',
          // backgroundColor: 'red'
        }}>

        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start' }}>
          <Box
            component='img'
            src={require('../images/rolling_robots_logo_small.png')}
            sx={{ maxWidth: '200px' }}
          />
        </Box>

        <Box
          sx={{
            gridColumn: { xs: '1/2', md: '2/3' },
            gridRow: { xs: '2/3', md: '1/2' },
            justifySelf: { xs: 'start', md: 'end' },
            display: 'flex',
            flexDirection: { xs: 'row', md: 'row' },
            marginTop: {xs: '10px'},
          }}>
            {footerButtons.map((button) => {
              return (
                <Typography
                  key={button.text}
                  sx={{
                    padding: { md: '0px 20px' },
                    paddingRight: { xs:'15px'},
                    color: '#c8102e',
                    fontSize: '0.8rem',
                    fontWeight: 600,
                    textAlign: { xs: 'left', md: 'center' },
                    '&:hover': { cursor: 'pointer' }
                  }}
                  onClick={() => navigateToTerms(button.sectionId)}
                >
                  {button.text}
                </Typography>
              )
            })}
        </Box>
      </Box>


      <Box 
        sx={{
        maxHeight: { sm: '75vh' },
        minHeight: { xs: '15vh', sm: '100px' },
        textAlign: 'left',
        }}
        className="contact_info_container">
          
        <div className="contact_info">   
          <Box>
            <Typography fontWeight='bold' sx={{ fontSize: '1rem' , color: '#a6a9aa'}}>
              Edorsum App Support
            </Typography>
            <div className='contact_info_email'>
              <MailOutlineIcon sx={{fontSize: '1.2rem' , color: "#c8102e", marginRight: '8px'}}/>
              <Typography sx={{ fontSize: '0.7rem', color: '#c8102e', fontWeight: 500 }}>
                edorsum@rollingrobots.com
              </Typography>
            </div>

            {/* <Typography
              sx={{
                fontSize: '0.7rem',
                fontWeight: 500,
                color: '#c8102e',
                '&:hover': { cursor: 'pointer' }
              }}
            >
              E-mail us
            </Typography> */}
          </Box>
        </div>
      </Box>

      <Divider sx={{ padding: '15px 0px' }}></Divider>
      <br></br>
    </>
  )
}

export default LogInUser
