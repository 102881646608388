import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  query,
  getDocs,
  orderBy,
  limit,
  startAfter,
  doc,
  getDoc
} from 'firebase/firestore'
import { useAuthState } from 'react-firebase-hooks/auth'
import { db, auth } from '../Firebase/FirebaseConfig'
import { useMediaQuery, Button } from '@mui/material'
import Project from '../Components/project'
import ProjectDetail from '../Components/projectDetail'
import ContentContainer from './ContentContainer'
// import UpdatePost from './UpdatePost'
import UpdateProjectVisibility from './UpdateVisibility'
import '../Styling/Project.css'

const ProjectBox = ({ projectsRef, parentComponent, handleEditProject }) => {
  const [allProjects, setAllProjects] = useState([])
  const [filteredProjects, setFilteredProjects] = useState([])
  const [previousProjects, setPreviousProjects] = useState([])
  const [selectedProject, setSelectedProject] = useState(null)
  const [openProjectDetail, setOpenProjectDetail] = useState(false)
  const [postIndex, setPostIndex] = useState(0)
  const [count, setCount] = useState(0)
  const [trigger, setTrigger] = useState(false)
  const [lastVisible, setLastVisible] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [user, loading, error] = useAuthState(auth)
  const [userData, setUserData] = useState(null)
  const navigate = useNavigate()

  const styles = {
    buttonBox: { display: 'flex', justifyContent: 'space-around' },
    button: { backgroundColor: '#C80F2D', margin: '5px', color: 'white' }
  }
  const mobile = useMediaQuery('(max-width:850px)')
  if (mobile) {
    styles.buttonBox = {
      display: 'flex',
      justifyContent: 'space-around',
      marginBottom: '55px'
    }
  }

  const filterProject = (
    userRole,
    userData,
    parentID,
    parentComponent,
    visible
  ) => {
    if (userRole === 3) {
      //User is admin, return true for all
      return true
    } else {
      //If project has a public visibility, return true
      if (visible === 0) {
        return true
      } else if (
        //If visibility type is friends only, check if user is friends with project creator
        visible === 1 &&
        userData?.friends.includes(parentID)
      ) {
        return true
        //If the parent component is the user profile, return true
      } else if (parentComponent === 'profile') {
        return true
        //If the project is the user's own project, return true
      } else if (user.uid === parentID) {
        return true
      } else {
        return false
      }
    }
  }

  useEffect(() => {
    async function handleAsync() {
      const userRef = doc(db, 'users', user.uid)
      const userSnap = await getDoc(userRef)
      const tmpUserData = userSnap.data()

      let userRole = 2
      if (userSnap.data().hasOwnProperty('role')) {
        userRole = userSnap.data()['role']
      }
      setUserData({ ...tmpUserData, id: userSnap.id })

      let projectsArr = []
      let tempPosts
      let emptyProject
      let projectsQuery
      if (count === 0) {
        projectsQuery = query(
          projectsRef,
          orderBy('created-date', 'desc')
          //limit(10)
        )
      } else {
        projectsQuery = query(
          projectsRef,
          orderBy('created-date', 'desc')
          // startAfter(lastVisible),
          // limit(10)
        )
      }

      const querySnapshot = await getDocs(projectsQuery)
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1])
      querySnapshot.forEach((doc) => {
        const docRef = doc.ref
        const parentCollectionRef = docRef.parent
        const parentDocRef = parentCollectionRef.parent
        let visible = 1

        if (doc.data().hasOwnProperty('visibility')) {
          visible = Number(doc.data().visibility)
        }

        if (
          //Filter projects based on visibility
          filterProject(
            userRole,
            userData,
            parentDocRef.id,
            parentComponent,
            visible
          )
        ) {
          const projDate = doc
            .data()
            ['created-date'].toDate()
            .toLocaleDateString()
          if (doc.data().posts != null) {
            tempPosts = doc.data().posts
            emptyProject = false
          } else {
            tempPosts = null
            emptyProject = true
          }
          const project = {
            id: doc.id,
            title: doc.data().title,
            date: projDate,
            posts: tempPosts,
            empty: emptyProject,
            userRef: parentDocRef,
            visibilityType: visible
          }
          projectsArr.push(project)
        }
      })
      setAllProjects(projectsArr)
    }
    handleAsync()
  }, [trigger])

  useEffect(() => {
    let start = count * 10
    let end = count * 10 + 9
    if (end >= allProjects.length) {
      end = allProjects.length
    }
    let tmpArr = [...allProjects].slice(start, end)
    setFilteredProjects(tmpArr)
  }, [allProjects, count])

  const handleNext = () => {
    // let tmpPrevProjects = [...previousProjects]
    // tmpPrevProjects.push(projects)
    // setPreviousProjects(tmpPrevProjects)
    // setTrigger(!trigger)
    window.scrollTo(0, 0)
    setCount(count + 1)
  }

  const handleBack = () => {
    // let tmpPrevProjects = [...previousProjects]
    // let projectsToDisplay = tmpPrevProjects.pop()
    // setPreviousProjects(tmpPrevProjects)
    // setProjects(projectsToDisplay)
    window.scrollTo(0, 0)
    if (count !== 0) {
      setCount(count - 1)
    }
  }

  // const selectProject = (project, index) => {
  //   setSelectedProject(project)
  //   setPostIndex(index)
  //   setOpenProjectDetail(true)
  // }

  const handleCloseProjectDetail = () => {
    setOpenProjectDetail(false)
    setSelectedProject(null)
  }

  const handleClick = (direction) => {
    if (direction === 'forward') {
      postIndex + 1 >= selectedProject.posts.length
        ? setPostIndex(0)
        : setPostIndex(postIndex + 1)
    } else {
      postIndex - 1 <= 0
        ? setPostIndex(selectedProject.posts.length - 1)
        : setPostIndex(postIndex - 1)
    }
  }

  const handleEditToggle = () => {
    setIsEdit(!isEdit)
  }

  /*const updatePost = async (updatedPosts) => {
    return new Promise((resolve) => {
      let update = UpdatePost(updatedPosts, selectedProject.id)
      resolve(update)
    })
  }

  const handleSave = (value) => {
    selectedProject.posts[postIndex]['description'] = value
    const updatedPosts = selectedProject.posts

    //update document
    updatePost(updatedPosts).then((resp) => {
      setIsEdit(false)
    })
  }*/

  const handleVisibilityChange = async (newAlignment, project) => {
    const value = newAlignment
    UpdateProjectVisibility(value, project).then((resp) => {
      // console.log("resp:", resp)
    })
  }

  return (
    <div className='project_container'>
      {filteredProjects.map((project) => {
        return (
          <Project
            project={project}
            key={project.id}
            parentComponent={parentComponent}
            visibility={project.visibilityType}
            updateVisibility={handleVisibilityChange}
            loggedInUser={userData}
            setLoggedInUser={setUserData}
            handleEdit={() => handleEditProject(project)}
          />
        )
      })}
      {/* <ProjectDetail
        isOpen={openProjectDetail}
        handleClose={handleCloseProjectDetail}
        project={selectedProject}
        index={postIndex}
        handleClick={handleClick}
        isEdit={isEdit}
        handleEditToggle={handleEditToggle}
        handleSave={handleSave}
        parentComponent={parentComponent}
      /> */}
      <div style={styles.buttonBox}>
        <>
          {count >= 1 ? (
            <Button
              variant='conatined'
              style={styles.button}
              onClick={handleBack}
            >
              Back
            </Button>
          ) : (
            <></>
          )}
        </>
        {count * 10 + 9 <= allProjects.length ? (
          <Button
            variant='conatined'
            style={styles.button}
            onClick={handleNext}
          >
            Next
          </Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default ProjectBox
