import React from 'react'

const TermsAndConditions = () => {
  return (
    <div style={{textAlign: 'left', width: '90%', margin: 'auto auto'}}>
      <h1 id='terms-and-conditions'>TERMS AND CONDITIONS</h1>

      <p>rollingrobots.com rollingrobots.net edorsum.com edorsum.net</p>

      <p>
        These terms and conditions (the "Terms and Conditions") govern the use
        of&nbsp;rollingrobots.com rollingrobots.net edorsum.com
        edorsum.net&nbsp;&nbsp;(the "Site"). This Site is owned and operated by
        Rolling Robots, Inc.. This Site is an ecommerce and educational website.
        <br />
        <br />
        By using this Site, you indicate that you have read and understand these
        Terms and Conditions and agree to abide by them at all times.
        <br />
        <br />
        THESE TERMS AND CONDITIONS CONTAIN A DISPUTE RESOLUTION CLAUSE THAT
        IMPACTS YOUR RIGHTS ABOUT HOW TO RESOLVE DISPUTES. PLEASE READ IT
        CAREFULLY.
      </p>

      <p>
        Intellectual Property
        <br />
        All content published and made available on our Site is the property of
        Rolling Robots, Inc. and the Site's creators. This includes, but is not
        limited to images, text, logos, documents, downloadable files and
        anything that contributes to the composition of our Site.
      </p>

      <p>
        Accounts
        <br />
        When you create an account on our Site, you agree to the following:
      </p>

      <ol start='1'>
        <li value='1'>
          You are solely responsible for your account and the security and
          privacy of your account, including passwords or sensitive information
          attached to that account; and
        </li>
        <li value='2'>
          All personal information you provide to us through your account is up
          to date, accurate, and truthful and that you will update your personal
          information if it changes.
        </li>
      </ol>

      <p>
        We reserve the right to suspend or terminate your account if you are
        using our Site illegally or if you violate these Terms and Conditions.
      </p>

      <p>
        Sale of Services
        <br />
        These Terms and Conditions govern the sale of services available on our
        Site.
      </p>

      <p>The following services are available on our Site:</p>

      <ul>
        <li value='1'>STEM Education Classes, Camps, and Memberships.</li>
      </ul>

      <p>
        The services will be paid for in full when the services are ordered.
      </p>

      <p>
        These Terms and Conditions apply to all the services that are displayed
        on our Site at the time you access it. All information, descriptions, or
        images that we provide about our services are as accurate as possible.
        However, we are not legally bound by such information, descriptions, or
        images as we cannot guarantee the accuracy of all services we provide.
        You agree to purchase services from our Site at your own risk.
      </p>

      <p>
        We reserve the right to modify, reject or cancel your order whenever it
        becomes necessary. If we cancel your order and have already processed
        your payment, we will give you a refund equal to the amount you paid.
        You agree that it is your responsibility to monitor your payment
        instrument to verify receipt of any refund.
      </p>

      <p>
        Subscriptions
        <br />
        Your subscription automatically renews and you will be automatically
        billed until we receive notification that you want to cancel the
        subscription.
      </p>

      <p>
        To cancel your subscription, please follow these steps: Subscriptions
        are paid at the start of the subscription period. There are no refunds.
        A user must cancel prior to the start of the next subscription period to
        not be charged for the next period. There are no partial refunds of any
        payments.
      </p>

      <p>
        Payments
        <br />
        We accept the following payment methods on our Site:
      </p>

      <ul>
        <li value='1'>Credit Card; and</li>
        <li value='2'>Debit.</li>
      </ul>

      <p>
        When you provide us with your payment information, you authorize our use
        of and access to the payment instrument you have chosen to use. By
        providing us with your payment information, you authorize us to charge
        the amount due to this payment instrument.
        <br />
        <br />
        If we believe your payment has violated any law or these Terms and
        Conditions, we reserve the right to cancel or reverse your transaction.
      </p>

      <p>
        Refunds
        <br />
        Refunds for Services
        <br />
        We provide refunds for services sold on our Site as follows:
      </p>

      <ul>
        <li value='1'>There are no full or partial refunds.</li>
      </ul>

      <p>
        Consumer Protection Law
        <br />
        Where any consumer protection legislation in your jurisdiction applies
        and cannot be excluded, these Terms and Conditions will not limit your
        legal rights and remedies under that legislation. These Terms and
        Conditions will be read subject to the mandatory provisions of that
        legislation. If there is a conflict between these Terms and Conditions
        and that legislation, the mandatory provisions of the legislation will
        apply.
      </p>

      <p>
        Limitation of Liability
        <br />
        Rolling Robots, Inc. and our directors, officers, agents, employees,
        subsidiaries, and affiliates will not be liable for any actions, claims,
        losses, damages, liabilities and expenses including legal fees from your
        use of the Site.
      </p>

      <p>
        Indemnity
        <br />
        Except where prohibited by law, by using this Site you indemnify and
        hold harmless Rolling Robots, Inc. and our directors, officers, agents,
        employees, subsidiaries, and affiliates from any actions, claims,
        losses, damages, liabilities and expenses including legal fees arising
        out of your use of our Site or your violation of these Terms and
        Conditions.
      </p>

      <p>
        Applicable Law
        <br />
        These Terms and Conditions are governed by the laws of the State of
        California.
      </p>

      <p>
        Dispute Resolution
        <br />
        Subject to any exceptions specified in these Terms and Conditions, if
        you and Rolling Robots, Inc. are unable to resolve any dispute through
        informal discussion, then you and Rolling Robots, Inc. agree to submit
        the issue before an arbitrator. The decision of the arbitrator will be
        final and binding. Any arbitrator must be a neutral party acceptable to
        both you and Rolling Robots, Inc.. The costs of any arbitration will be
        paid by the unsuccessful party.
      </p>

      <p>
        Notwithstanding any other provision in these Terms and Conditions, you
        and Rolling Robots, Inc. agree that you both retain the right to bring
        an action in small claims court and to bring an action for injunctive
        relief or intellectual property infringement.
      </p>

      <p>
        Severability
        <br />
        If at any time any of the provisions set forth in these Terms and
        Conditions are found to be inconsistent or invalid under applicable
        laws, those provisions will be deemed void and will be removed from
        these Terms and Conditions. All other provisions will not be affected by
        the removal and the rest of these Terms and Conditions will still be
        considered valid.
      </p>

      <p>
        Changes
        <br />
        These Terms and Conditions may be amended from time to time in order to
        maintain compliance with the law and to reflect any changes to the way
        we operate our Site and the way we expect users to behave on our Site.
        We will notify users by email of changes to these Terms and Conditions
        or post a notice on our Site.
      </p>

      <p>
        Contact Details
        <br />
        Please contact us if you have any questions or concerns. Our contact
        details are as follows:
        <br />
        <br />
        (888) 762-6808
        <br />
        info@rollingrobots.com
        <br />
        704 BART EARLE WAY 201,Rolling Hills Estates, CA 90274
      </p>

      <p>
        You can also contact us through the feedback form available on our Site.
      </p>

      <p>Effective Date: 22nd day of February, 2022</p>

      <p>&nbsp;</p>

      <p>&nbsp;</p>

      <h1 id='privacy-policy'>Privacy Policy</h1>

      <p>
        Type of website and app: Technical Documentation
        <br />
        Effective date: 2nd day of October, 2023
      </p>

      <p>
        edorsum.com .net and the edorsum ios app (the "Site") is owned and operated by Rolling
        Robots, Inc.. Rolling Robots, Inc. can be contacted at:
        <br />
        <br />
        info@rollingrobots.com
        <br />
        (888) 762-6808
        <br />
        704 BART EARLE WAY 201, Rolling Hills Estates, CA 90274
      </p>

      <p>
        Purpose
        <br />
        The purpose of this privacy policy (this "Privacy Policy") is to inform
        users of our Site of the following:
      </p>

      <ol start='1'>
        <li value='1'>The personal data we will collect;</li>
        <li value='2'>Use of collected data;</li>
        <li value='3'>Who has access to the data collected;</li>
        <li value='4'>The rights of Site users; and</li>
        <li value='5'>The Site's cookie policy.</li>
      </ol>

      <p>
        This Privacy Policy applies in addition to the terms and conditions of
        our Site.
      </p>

      <p>
        Consent
        <br />
        By using our Site users agree that they consent to:
      </p>

      <ol start='1'>
        <li value='1'>The conditions set out in this Privacy Policy; and</li>
        <li value='2'>
          The collection, use, and retention of the data listed in this Privacy
          Policy.
        </li>
      </ol>

      <p>
        Personal Data We Collect
        <br />
        We only collect data that helps us achieve the purpose set out in this
        Privacy Policy. We will not collect any additional data beyond the data
        listed below without notifying you first.
        <br />
        &nbsp;
      </p>

      <p>
        Data Collected Automatically
        <br />
        When you visit and use our Site, we may automatically collect and store
        the following information:
      </p>

      <ol start='1'>
        <li value='1'>IP address;</li>
        <li value='2'>Location;</li>
        <li value='3'>Hardware and software details;</li>
        <li value='4'>Clicked links; and</li>
        <li value='5'>Content viewed.</li>
      </ol>

      <p>
        Data Collected in a Non-Automatic Way
        <br />
        We may also collect the following data when you perform certain
        functions on our Site:
      </p>

      <ol start='1'>
        <li value='1'>User created Username;</li>
        <li value='2'>Email address;</li>
        <li value='3'>Content in user generated posts;</li>
      </ol>

      <p>This data may be collected using the following methods:</p>

      <ol start='1'>
        <li value='1'>Creating Account</li>
        <li value='2'>User Posting</li>
      </ol>

      <p>
        How We Use Personal Data
        <br />
        Data collected on our Site will only be used for the purposes specified
        in this Privacy Policy or indicated on the relevant pages of our Site.
        We will not use your data beyond what we disclose in this Privacy
        Policy.
        <br />
        <br />
        An irrevocable unlimited right is granted to Rolling Robots Inc. to use 
        data posted by users in any media at any time in perpetuity. Without 
        attribution or compensation.
        <br />
        <br />
        The data we collect automatically is used for the following purposes:
      </p>

      <ol start='1'>
        <li value='1'>Statistics.</li>
      </ol>

      <p>
        The data we collect when the user performs certain functions may be used
        for the following purposes:
      </p>

      <ol start='1'>
        <li value='1'>Communication; and</li>
        <li value='2'>Guidance.</li>
      </ol>

      <p>
        Who We Share Personal Data With
        <br />
        Employees
        <br />
        We may disclose user data to any member of our organization who
        reasonably needs access to user data to achieve the purposes set out in
        this Privacy Policy.
      </p>

      <p>
        Other Disclosures
        <br />
        We will not sell or share your data with other third parties, except in
        the following cases:
      </p>

      <ol start='1'>
        <li value='1'>If the law requires it;</li>
        <li value='2'>If it is required for any legal proceeding;</li>
        <li value='3'>To prove or protect our legal rights; and</li>
        <li value='4'>
          To buyers or potential buyers of this company in the event that we
          seek to sell the company.
        </li>
      </ol>

      <p>
        If you follow hyperlinks from our Site to another Site, please note that
        we are not responsible for and have no control over their privacy
        policies and practices.
      </p>

      <p>
        How Long We Store Personal Data
        <br />
        User data will be stored until the purpose the data was collected for
        has been achieved.
        <br />
        <br />
        You will be notified if your data is kept for longer than this period.
      </p>

      <p>
        How We Protect Your Personal Data
        <br />
        In order to protect your security, we use the strongest available
        browser encryption and store all of our data on servers in secure
        facilities. All data is only accessible to our employees. Our employees
        are bound by strict confidentiality agreements and a breach of this
        agreement would result in the employee's termination.
        <br />
        <br />
        While we take all reasonable precautions to ensure that user data is
        secure and that users are protected, there always remains the risk of
        harm. The Internet as a whole can be insecure at times and therefore we
        are unable to guarantee the security of user data beyond what is
        reasonably practical.
      </p>

      <p>
        Children
        <br />
        We do not knowingly collect or use personal data from children under 13
        years of age. If we learn that we have collected personal data from a
        child under 13 years of age, the personal data will be deleted as soon
        as possible. If a child under 13 years of age has provided us with
        personal data their parent or guardian may contact our privacy officer.
      </p>

      <p>
        Please see our&nbsp;Children's Privacy Policy&nbsp;for additional
        information on how we deal with children's personal data.
      </p>

      <p>
        How to Access, Modify, Delete, or Challenge the Data Collected
        <br />
        If you would like to know if we have collected your personal data, how
        we have used your personal data, if we have disclosed your personal data
        and to who we disclosed your personal data, or if you would like your
        data to be deleted or modified in any way, please contact our privacy
        officer here:
        <br />
        <br />
        George Kirkman
        <br />
        info@rollingrobots.com
        <br />
        (888) 762-6808
        <br />
        704 BART EARLE WAY 201, Rolling Hills Estates, CA 90274
      </p>

      <p>
        Do Not Track Notice
        <br />
        Do Not Track ("DNT") is a privacy preference that you can set in certain
        web browsers. We do not track the users of our Site over time and across
        third party websites and therefore do not respond to browser-initiated
        DNT signals.
      </p>

      <p>
        How to Opt-Out of Data Collection, Use or Disclosure
        <br />
        In addition to the method(s) described in the&nbsp;How to Access,
        Modify, Delete, or Challenge the Data Collected&nbsp;section, we provide
        the following specific opt-out methods for the forms of collection, use,
        or disclosure of your personal data specified below:
      </p>

      <ol start='1'>
        <li value='1'>
          You can opt-out of the use of your personal data for marketing emails.
          You can opt-out by clicking "unsubscribe" on the bottom of any
          marketing email.
        </li>
      </ol>

      <p>
        Cookie Policy
        <br />A cookie is a small file, stored on a user's hard drive by a
        website. Its purpose is to collect data relating to the user's browsing
        habits. You can choose to be notified each time a cookie is transmitted.
        You can also choose to disable cookies entirely in your internet
        browser, but this may decrease the quality of your user experience.
      </p>

      <p>We use the following types of cookies on our Site:</p>

      <ol start='1'>
        <li value='1'>
          Functional cookies
          <br />
          Functional cookies are used to remember the selections you make on our
          Site so that your selections are saved for your next visits;
        </li>
        <li value='2'>
          Analytical cookies
          <br />
          Analytical cookies allow us to improve the design and functionality of
          our Site by collecting data on how you access our Site, for example
          data on the content you access, how long you stay on our Site, etc;
          and
        </li>
        <li value='3'>
          Targeting cookies
          <br />
          Targeting cookies collect data on how you use the Site and your
          preferences. This allows us to personalize the information you see on
          our Site for you.
        </li>
      </ol>

      <p>
        Modifications
        <br />
        This Privacy Policy may be amended from time to time in order to
        maintain compliance with the law and to reflect any changes to our data
        collection process. When we amend this Privacy Policy we will update the
        "Effective Date" at the top of this Privacy Policy. We recommend that
        our users periodically review our Privacy Policy to ensure that they are
        notified of any updates. If necessary, we may notify users by email of
        changes to this Privacy Policy.
      </p>

      <p>
        Contact Information
        <br />
        If you have any questions, concerns or complaints, you can contact our
        privacy officer, George Kirkman, at:
        <br />
        <br />
        info@rollingrobots.com
        <br />
        (888) 762-6808
        <br />
        704 BART EARLE WAY 201, Rolling Hills Estates, CA 90274
      </p>

      <p>
        Children's Privacy Policy
        <br />
        Website: edorsum.net (the "Site")
      </p>

      <p>
        This Children's Privacy Policy supplements the rollingrobots.com .net
        Privacy Policy and applies to the personal information of children in
        the United States who are under the age of thirteen (13) ("child" or
        "children"). This Children's Privacy Policy is made in accordance with
        the United States Children's Online Privacy Protection Act ("COPPA").
        Any terms defined in COPPA have the same meaning when used in this
        Children's Privacy Policy.
      </p>

      <p>
        Children's Data We Collect
        <br />
        We do not knowingly collect the personal information of children under
        the age of thirteen (13).
      </p>

      <p>
        Public Availability
        <br />
        Children are not able to make their personal information publicly
        available on our Site.
      </p>

      <p>
        Third Party Operators
        <br />
        Third parties do not use our Site to collect personal information about
        children who use our Site.
      </p>

      <p>
        Parental Rights
        <br />A child's parent or guardian can review their child's personal
        information, direct us to delete their child's personal information, and
        refuse any further collection or use of their child's personal
        information. A parent or guardian can also agree to the collection and
        use of their child's personal information but not allow us to disclose
        that personal information to third parties, unless disclosure is part of
        the service provided by our Site. A parent or guardian can exercise
        these rights via the following method(s):
      </p>

      <ol start='1'>
        <li value='1'>
          Parents can request access to and delete their child's personal
          information, and remove or provide consent to the collection of their
          child's personal information by logging into their child's account
          through our homepage..
        </li>
      </ol>

      <p>
        We will not require a child to disclose more information than is
        reasonably necessary to participate in an activity on our Site.
      </p>

      <p>
        Contact Information
        <br />
        Questions and concerns about this Children's Privacy Policy or about our
        collection and use of children's personal information, can be sent to:
        <br />
        <br />
        Name: George Kirkman
        <br />
        Email: info@rollingrobots.com
        <br />
        Address: 704 BART EARLE WAY 201
        <br />
        Phone number: (888) 762-6808
      </p>
    </div>
  )
}

export default TermsAndConditions