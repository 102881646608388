import React, { useEffect, useState } from 'react'
import { getDoc } from 'firebase/firestore'
import {
  Avatar,
  Typography,
  Paper,
  Divider,
  Tooltip,
  Button,
  Menu,
  MenuItem
} from '@mui/material'
import { updateDoc, arrayUnion, arrayRemove, doc } from 'firebase/firestore'
import { db } from '../Firebase/FirebaseConfig'
import '../Styling/Project.css'
import ProjectFeedback from './projectFeedback'
import FriendIcon from './FriendIcon'
import LanguageIcon from '@mui/icons-material/Language'

const Project = ({
  project,
  parentComponent,
  visibility,
  updateVisibility,
  loggedInUser,
  setLoggedInUser,
  handleEdit
}) => {
  const [projectUser, setProjectUser] = useState(null)
  const [profilePic, setProfilePic] = useState('')
  const [alignmentName, setAlignmentName] = useState(null)
  const [expand, setExpand] = useState(false)
  const [relation, setRelation] = useState('none')
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const getVisibility = (value) => {
    if (value === 0) {
      setAlignmentName('Public')
    } else if (value === 1) {
      setAlignmentName('Friends')
    } else {
      setAlignmentName('Private')
    }
  }

  useEffect(() => {
    async function handleAsync() {
      //Get user
      const userSnapshot = await getDoc(project.userRef)
      if (userSnapshot.data()['profile-image-url']) {
        const image = userSnapshot.data()['profile-image-url']
        setProfilePic(image)
      }
      setProjectUser({ ...userSnapshot.data(), id: userSnapshot.id })
    }
    if (project.userRef) {
      handleAsync()
    }
    getVisibility(visibility)
  }, [])

  useEffect(() => {
    if(projectUser !== null){
      if (loggedInUser?.id === projectUser?.id) {
        setRelation('none')
      } else if (loggedInUser.friends !== undefined && loggedInUser?.friends.includes(projectUser?.id)) {
        setRelation('friend')
      } else if (loggedInUser.sentRequests !== undefined && loggedInUser?.sentRequests.includes(projectUser?.id)) {
        setRelation('sentRequest')
      } else if (loggedInUser.receivedRequests !== undefined && loggedInUser?.receivedRequests.includes(projectUser?.id)) {
        setRelation('receivedRequest')
      } else {
        setRelation('none')
      }
    }
  }, [loggedInUser, projectUser])

  const expandProject = () => {
    setExpand(true)
  }

  const retractProject = () => {
    setExpand(false)
  }

  const handleRemoveFriend = async () => {
    //Update user account
    const userRef = doc(db, 'users', loggedInUser.id)
    await updateDoc(userRef, { friends: arrayRemove(projectUser.id) })

    //Update friend account
    await updateDoc(project.userRef, { friends: arrayRemove(loggedInUser.id) })

    //Update logged in user
    let tmpLoggedInUser = { ...loggedInUser }
    let index = tmpLoggedInUser.friends.findIndex(
      (friendID) => friendID === projectUser.id
    )
    tmpLoggedInUser.friends.splice(index, 1)
    setLoggedInUser(tmpLoggedInUser)
  }

  const handleRequestFriend = async () => {
    //Update user account
    const userRef = doc(db, 'users', loggedInUser.id)
    await updateDoc(userRef, { sentRequests: arrayUnion(projectUser.id) })

    //Update friend account
    await updateDoc(project.userRef, {
      receivedRequests: arrayUnion(loggedInUser.id)
    })

    //Update logged in user
    let tmpLoggedInUser = { ...loggedInUser }
    tmpLoggedInUser['sentRequests'].push(projectUser.id)
    setLoggedInUser(tmpLoggedInUser)
  }

  const handleAcceptFriend = async () => {
    //update logged in account account
    const userRef = doc(db, 'users', loggedInUser.id)
    await updateDoc(userRef, { receivedRequests: arrayRemove(projectUser.id) })
    await updateDoc(userRef, { friends: arrayUnion(projectUser.id) })

    //update friend account
    await updateDoc(project.userRef, {
      sentRequests: arrayRemove(loggedInUser.id)
    })
    await updateDoc(project.userRef, { friends: arrayUnion(loggedInUser.id) })

    //Update logged in user
    let tmpLoggedInUser = { ...loggedInUser }
    let index = tmpLoggedInUser.receivedRequests.findIndex(
      (friendID) => friendID === projectUser.id
    )
    tmpLoggedInUser.receivedRequests.splice(index, 1)
    tmpLoggedInUser['friends'].push(projectUser.id)
    setLoggedInUser(tmpLoggedInUser)
  }

  const handleRejectFriend = async () => {
    //update user account
    const userRef = doc(db, 'users', loggedInUser.id)
    await updateDoc(userRef, { receivedRequests: arrayRemove(projectUser.id) })

    //update friend account
    await updateDoc(project.userRef, {
      sentRequests: arrayRemove(loggedInUser.id)
    })

    //Update logged in user
    let tmpLoggedInUser = { ...loggedInUser }
    let index = tmpLoggedInUser.receivedRequests.findIndex(
      (friendID) => friendID === projectUser.id
    )
    tmpLoggedInUser.receivedRequests.splice(index, 1)
    setLoggedInUser(tmpLoggedInUser)
  }

  const handleVisibilityClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleVisibilityClose = () => {
    setAnchorEl(null)
  }

  const handleChangeVisibility = async (newValue) => {
    updateVisibility(newValue, project)
    getVisibility(newValue)
    handleVisibilityClose()
  }

  return (
    <Paper className='project'>
      <div className='project_header'>
        <div className='project_info'>
          <>
            {parentComponent === 'home' ? (
              <Avatar
                src={profilePic}
                sx={{ width: 'auto', height: '50px' }}
                className='avatar'
              />
            ) : (
              <></>
            )}
          </>
          <div
            className={
              parentComponent === 'home'
                ? 'project_details_home'
                : 'project_details_profile'
            }
          >
            <Typography align='left' variant='h6'>
              {project.title}
            </Typography>
            {parentComponent === 'home' ? (
              <div className='user_name'>
                <Typography variant='body2'>{projectUser?.name}</Typography>
                {loggedInUser?.id !== projectUser?.id ? (
                  <FriendIcon
                    relation={relation}
                    handleRemove={handleRemoveFriend}
                    handleAccept={handleAcceptFriend}
                    handleReject={handleRejectFriend}
                    handleRequest={handleRequestFriend}
                  />
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {projectUser?.id === loggedInUser?.id ? (
          <div className='project_date'>
            <Typography variant='caption'>{project.date}</Typography>
            <Tooltip title='Set Visibility'>
              <div
                className='project_visibility'
                onClick={handleVisibilityClick}
                aria-controls={open ? 'visibility-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
              >
                <LanguageIcon
                  sx={{ fontSize: 15 }}
                  className='visibility_icon'
                />
                <Typography variant='caption'>{alignmentName}</Typography>
              </div>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id='visibility-menu'
              open={open}
              onClose={handleVisibilityClose}
              onClick={handleVisibilityClose}
            >
              <MenuItem onClick={() => handleChangeVisibility(0)}>
                Public
              </MenuItem>
              <MenuItem onClick={() => handleChangeVisibility(1)}>
                Friends Only
              </MenuItem>
              <MenuItem onClick={() => handleChangeVisibility(2)}>
                Private
              </MenuItem>
            </Menu>
            
            {parentComponent === 'profile' ? (
            <>
              <Button
                variant='text'
                color='primary'
                className='project_visibility'
                style={{marginTop: '5px'}}
                onClick={() => handleEdit()}
              >EDIT</Button>
            </>) : (<></>)}
          </div>
        ) : (
          <div className='project_date'>
            <Typography variant='caption'>{project.date}</Typography>
            <LanguageIcon sx={{ fontSize: 15 }} className='visibility_icon' />
            <Typography variant='caption'>{alignmentName}</Typography>
          </div>
        )}

      </div>
      <Divider />
      <div className='project_body'>
        {project.posts ? (
          <>
            {project.posts.map((post, index) => {
              if (index < 3 || expand) {
                return (
                  <div className='post_container'>
                    <img
                      src={`${post['image-url']}`}
                      alt='An image of a project'
                      loading='lazy'
                      className='post_img'
                    />

                    <Typography className='post_description' color='#505050'>
                      {post.description}
                    </Typography>
                  </div>
                )
              }
            })}
            {project.posts.length > 3 && !expand ? (
              <Button onClick={expandProject}>Expand</Button>
            ) : (
              <>
                {project.posts.length > 3 && expand ? (
                  <Button onClick={retractProject}>Retract</Button>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        ) : (
          <Typography>No posts yet</Typography>
        )}
      </div>
      <div className='project_feedback'>
        <Divider />
        <ProjectFeedback projectID={project.id} />
      </div>
    </Paper>
  )
}

export default Project
