import React from 'react'
import SaveReport from './SaveReport'
import { serverTimestamp } from 'firebase/firestore'
import AddVersion from './AddVersion'

const generateReport = async (selectedData, id) => {
  let essayData
  let captionPairData
  if (id) {
    essayData = selectedData.essayData
    captionPairData = selectedData.captionPairData
  } else {
    essayData = Object.values(selectedData)
    .filter((pair) => pair.caption)
    .map((pair) => pair.caption)

   captionPairData = Object.values(selectedData).filter((pair) => pair['image-url'])
  }
  // console.log(essayData)
  // console.log(captionPairData)

  const generateEssay = () => {
    var xhttp = new XMLHttpRequest()
    var data = new FormData()

    data.append('descriptions', essayData.join('[|~|]'))

    return new Promise((resolve) => {
      xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
          resolve(this.responseText)
        } else {
          //console.log('essay loading')
        }
      }
      xhttp.open('POST', 'https://edorsum-ai.com/generate-essay')
      xhttp.send(data)
    })
  }

  function generateCaptions(postDesc) {
    // console.log('generating caption')
    var xhttp = new XMLHttpRequest()
    var data = new FormData()
    var description = postDesc
    data.append('description', description)

    return new Promise((resolve) => {
      xhttp.onreadystatechange = function () {
        if (this.status == 200) {
          if (this.readyState == 4) {
            resolve(this.responseText)
          }
        } else {
          //console.log('caption loading')
        }
      }
      xhttp.open('POST', 'https://edorsum-ai.com/generate-caption', true)
      xhttp.send(data)
    })
  }
  //generate captions
  let pairsResponse = []
  captionPairData.forEach((pair) => {
    if (pair.caption) {
      generateCaptions(pair.caption).then((resp) => {
        pairsResponse.push({ caption: resp, 'image-url': pair['image-url'] })
      })
    } else {
      pairsResponse.push({ caption: '', 'image-url': pair['image-url'] })
    }
  })

  //generate essay
  let essayResponse = await generateEssay()

  const saveReport = async () => {
    const tempTitle = 'No Title'
    return new Promise((resolve) => {
      let reportID = SaveReport(
        essayResponse,
        essayData,
        pairsResponse,
        tempTitle
      )
      resolve(reportID)
    })
  }

  // let updatedVersions = [
  //   ...versions,
  //   { essay: essayResponse, figures: pairsResponse }
  // ]

  // const addVersion = async () => {
  //   return new Promise((resolve) => {
  //     let update = AddVersion(updatedVersions, report.id)
  //     resolve(update)
  //   })
  // }
  //saveReport

  const addVersion = async () => {
    return new Promise((resolve) => {
      // let obj = {essay: essayResponse, figures: pairsResponse}
      console.log(essayResponse, pairsResponse)
      let update = AddVersion(essayResponse, pairsResponse, id)
      resolve(update)
    })
  }
  let date = new Date().toLocaleDateString() //serverTimestamp has no value until it's synced with firestore
  let response

  if (id) {
    response = await addVersion()
  } else {
  let docID = await saveReport()
  response = {
    currentVer: 0,
    id: docID,
    title: '',
    // descriptions: essayData,
    versions: [{ essay: essayResponse, figures: pairsResponse }],
    'date-created': date
  }
  }
  return response
}

export default generateReport
