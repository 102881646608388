//Updates existing report in Firebase
import React, {useState, useEffect} from "react";
import{db, auth} from '../Firebase/FirebaseConfig';
import { doc, updateDoc, serverTimestamp} from "firebase/firestore";


const UpdateReport = async (currentVer, updatedVersions, docID, reportTitle) => {    
    console.log("UpdateReport.js")

    try{
        const reportRef = doc(db, "reports", docID);
        await updateDoc(reportRef, { 
            title: reportTitle,
            currentVer: currentVer,
            versions: updatedVersions,
            // essay: essayTemp,
            // figures: pairsTemp,
            'date-updated': serverTimestamp()
        });
        console.log("ID of updated document:", reportRef.id)

    } catch(err) {
        console.log("error:", err)
    }
    
};
export default UpdateReport;