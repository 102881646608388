//component rendered in the modal when user clicks the "EDIT" button on the AI generated report
import React, { useState, useEffect, useCallback } from 'react'
import '../Styling/Project.css'
import '../Styling/Default.css'

import { db } from '../Firebase/FirebaseConfig'
import { getDoc, doc, updateDoc } from 'firebase/firestore'

import { Button, DialogActions, Grid, TextField } from '@mui/material'
import UpdateReport from './UpdateReport'

const EditReportView = (props) => {
  const thisID = props.id
  const [essay, setEssay] = useState(null)
  const [figures, setFigures] = useState([])
  const [title, setTitle] = useState(null)
  const [report, setReport] = useState(null)

  let figurePairs = figures

  const GetReport = async () => {
    try {
      const reportRef = doc(db, 'reports', thisID)
      const reportSnapshot = await getDoc(reportRef)
      const tmpReport = reportSnapshot.data()
      setReport(tmpReport)
      if (tmpReport.title === null) {
        setTitle('No Title')
      } else {
        setTitle(tmpReport.title)
      }
      if (tmpReport.versions) {
        let version = tmpReport.versions[tmpReport.versions.length - 1]
        setEssay(version.essay)
        setFigures(version.figures)
      } else {
        setEssay(tmpReport.essay)
        setFigures(tmpReport.figures)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleChange = (event, setEssay) => {
    setEssay(event.target.value)
  }
  const handleTitleChange = (e, setTitle) => {
    setTitle(e.target.value)
  }

  let clone
  const onChange = useCallback(
    (id, value) => {
      clone = [...figures]
      let obj = clone[id]
      obj.caption = value
      clone[id] = obj

      setFigures((prevItems) => {
        return prevItems.map((item, index) =>
          index !== id
            ? item
            : { caption: obj.caption, 'image-url': obj['image-url'] }
        )
      })
    },
    [figurePairs]
  )

  useEffect(() => {
    GetReport()
  }, [])

  const updateReport = async () => {
    const reportRef = doc(db, 'reports', thisID)
    if (report.versions) {
      let tmpVersions = [...report.versions]
      let index = tmpVersions.length - 1
      tmpVersions[index] = { essay: essay, figures: figures }
      await updateDoc(reportRef, { title: title, versions: tmpVersions })
    } else {
      await updateDoc(reportRef, {
        title: title,
        essay: essay,
        figures: figures
        //'date-updated': serverTimestamp()
      })
    }

    props.setTrigger(!props.trigger)
    props.close()
  }

  const RenderFigures = () => {
    return (
      <Grid container spacing={0}>
        {figures?.map((item, index) => (
          <>
            <div key={index}>
              <Grid lg={3}>
                <div>
                  <img
                    style={{ margin: '5px auto', display: 'block' }}
                    className='image_box'
                    src={item['image-url']}
                  />
                </div>
              </Grid>

              <Grid lg={9}>
                <div>
                  <TextField
                    style={{ marginTop: '5px' }}
                    key={item['image-url']}
                    id={index}
                    className='defaultBox'
                    value={item.caption}
                    multiline
                    fullWidth
                    onChange={(e) => onChange(e.target.id, e.target.value)}
                  />
                </div>
              </Grid>
            </div>
          </>
        ))}
      </Grid>
    )
  }

  return (
    <div>
      <div className='response'>
        <b>Title</b>
        <TextField
          name='report'
          className='defaultBox'
          value={title}
          onChange={(e) => handleTitleChange(e, setTitle)}
        />
      </div>
      <div className='response'>
        <TextField
          name='report'
          className='defaultBox'
          multiline
          value={essay}
          onChange={(e) => handleChange(e, setEssay)}
        />
      </div>
      <div className='response'>{RenderFigures()}</div>
      <DialogActions>
        <Button variant='contained' className='resp_btn' onClick={updateReport}>
          Save Edits
        </Button>
      </DialogActions>
    </div>
  )
}
export default EditReportView
